<template>
  <div class="top-toolbar" style="display: flex; align-items: center; justify-content: space-between;">
    <div class="left-actions" style="display: flex; align-items: center; gap: 10px;">
      <t-button
          :loading="isLoading"
          variant="outline"
          theme="success"
          class="custom-button"
          @click="runCode"
      >
        <template v-if="!isLoading" #icon>
          <PlayCircleStrokeIcon/>
        </template>
        <span v-if="!isLoading">运行</span>
        <span v-else>运行中</span>
      </t-button>
      
      <t-select
          v-model="currentTheme"
          class="theme-select"
          placeholder="选择主题"
          @change="changeTheme"
          style="width: 120px;"
      >
        <t-option v-for="theme in themes" :key="theme.value" :value="theme.value" :label="theme.label"/>
      </t-select>

      <t-select
          v-model="currentLanguage"
          class="language-select"
          placeholder="选择语言"
          @change="changeLanguage"
          style="width: 120px; margin-left: 8px;"
      >
        <t-option v-for="lang in languages" :key="lang.value" :value="lang.value" :label="lang.label"/>
      </t-select>

      <t-button
        variant="outline"
        theme="default"
        @click="openSettings"
      >
        <template #icon>
          <SettingIcon />
        </template>
      </t-button>

      <t-button
        variant="outline"
        theme="default"
        @click="showSnippetsManager = true"
      >
        <template #icon>
          <CodeIcon />
        </template>
        <span>代码片段</span>
      </t-button>

      <TemplatesManager
        v-model:code="editorStore.code"
        :language="currentLanguage"
        @update:code="updateEditorContent"
      />
      <t-button
        variant="outline"
        theme="primary"
        @click="openAIExplainer"
      >
        <template #icon>
          <AppIcon />
        </template>
        <span>AI助手</span>
      </t-button>
      
      <ShareCode 
        :code="getCurrentCode()"
        :language="currentLanguage"
      />
      
    </div>

    <div class="right-actions" style="display: flex; align-items: center; gap: 12px; flex: 1; justify-content: flex-end; max-width: 50%;">
      <!-- 代码鼓励组件 -->
      <CodeEncouragement
        :code="getCurrentCode()"
        class="encouragement-component"
      />
      
      <!-- 语音聊天组件（无UI，仅提供功能） -->
      <VoiceChat 
        ref="voiceChatRef"
        :visible="voiceChatVisible"
        @update:visible="voiceChatVisible = $event"
        @connected="handleVoiceChatConnected"
        @disconnected="handleVoiceChatDisconnected"
        @mute-change="handleMuteChange"
        @user-count-change="handleUserCountChange"
      />
      
      <!-- 通话中的控制按钮 - 放在语音聊天按钮左侧 -->
      <div v-if="isVoiceChatConnected" class="call-controls">
        <!-- 通话状态指示条 -->
        <div class="call-indicator">
          <div class="pulse-dot"></div>
          <span>通话中 · {{ voiceChatUserCount }}人</span>
        </div>
        
        <!-- 控制按钮组 -->
        <div class="call-buttons">
          <button 
            class="voice-btn mic-btn" 
            :class="{'muted': isVoiceChatMuted}"
            @click="toggleVoiceChatMute"
          >
            <div class="btn-icon-wrapper">
              <div class="mic-icon"></div>
              <div v-if="isVoiceChatMuted" class="mute-line"></div>
            </div>
          </button>
          
          <button 
            class="voice-btn hangup-btn"
            @click="leaveVoiceChat"
          >
            <div class="btn-icon-wrapper">
              <div class="hangup-icon"></div>
            </div>
          </button>
        </div>
      </div>
      
      <!-- 语音聊天按钮 -->
      <button
        v-if="!isVoiceChatConnected"
        class="voice-chat-btn"
        @click="openVoiceChat"
        :title="'开始语音通话'"
      >
        <div class="voice-chat-icon"></div>
        <span>队友聊天</span>
      </button>
    </div>
  </div>
  
  <div class="editor-container">
    <div class="editor-section" :style="{ flex: leftFlex }">
      <div class="bordered-div">
        <div id="code-editor" ref="codeEditorRef" style="height: 80vh"/>
      </div>
    </div>
    
    <div 
      class="resizer" 
      @mousedown="startResize"
      @touchstart="startResize"
    >
      <div class="resizer-line"></div>
    </div>
    
    <div class="right-section" :style="{ flex: rightFlex }">
      <div class="container">
        <div class="action-buttons">
          <t-button 
            variant="outline" 
            theme="success" 
            :loading="isLoading"
            @click="runCode"
          >
            <template v-if="!isLoading" #icon>
              <PlayCircleStrokeIcon/>
            </template>
            <span v-if="!isLoading">运行</span>
            <span v-else>运行中</span>
          </t-button>

          <t-button 
            variant="outline" 
            theme="primary" 
            @click="openRunDetails"
            style="flex: 1;"
          >
            运行详情
          </t-button>
        </div>
        <div class="scrollable-container">
          <div class="test-cases-container">
            <div v-for="(testCase, index) in testCases" :key="index" class="test-case-item">
              <div class="test-case-header">
                <span>测试用例 #{{ index + 1 }}</span>
                <t-button 
                  theme="danger" 
                  variant="text" 
                  size="small" 
                  @click="removeTestCase(index)"
                  v-if="testCases.length > 1"
                >
                  删除
                </t-button>
              </div>
              <div 
                class="test-case-content"
                :class="{
                  'success-case': runInfo?.[index]?.status === 'Accepted' && isOutputMatch(runInfo[index], index),
                  'error-case': runInfo?.[index]?.status === 'Accepted' && !isOutputMatch(runInfo[index], index)
                }"
              >
                <div class="input-section">
                  <div class="input-label">输入：</div>
                  <t-textarea
                    v-model="testCase.input"
                    :placeholder="`输入第 ${index + 1} 组测试用例`"
                    :autosize="{ minRows: 2, maxRows: 3 }"
                  />
                </div>
                <div class="expected-section">
                  <div class="input-label">预期输出：</div>
                  <t-textarea
                    v-model="testCase.expected"
                    :placeholder="'输入预期结果（可选）'"
                    :autosize="{ minRows: 2, maxRows: 3 }"
                  />
                </div>
                <div class="actual-section" 
                     v-if="runInfo?.[index]?.status === 'Accepted' && 
                          testCase.expected && 
                          !isOutputMatch(runInfo[index], index)"
                >
                  <div class="input-label error-text">实际输出：</div>
                  <t-textarea
                    readonly
                    :value="runInfo[index].files?.stdout || ''"
                    :autosize="{ minRows: 2, maxRows: 3 }"
                  />
                </div>
              </div>
            </div>
            <t-button 
              theme="primary" 
              variant="text" 
              size="small" 
              @click="addTestCase"
              style="margin-top: 8px;"
            >
              + 添加测试用例
            </t-button>
          </div>

          <div class="output-section">
            <t-alert 
              v-if="runInfo.length > 0"
              :theme="getAlertTheme()"
              :message="getAlertMessage()"
            />
            <div v-if="output" class="output-container">
              <h4>输出：</h4>
              <pre class="output-content">{{ output }}</pre>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- 添加 RunDetails 组件 -->
  <RunDetails 
    :runInfo="runInfo" 
    :visible="drawerVisible" 
    :testCases="testCases"
    @update:visible="updateDrawerVisible"
  />

  <EditorSettings
    :visible="settingsVisible"
    :editor="codeEditor"
    @update:visible="updateSettingsVisible"
  />

  <!-- 添加 AIExplainer 组件 -->
  <AIExplainer
    :visible="aiExplainerVisible"
    :code="getCurrentCode()"
    @update:visible="updateAIExplainerVisible"
  />

  <div class="save-status" v-if="showSaveStatus">
    <t-tag theme="success" variant="light">已自动保存</t-tag>
  </div>

  <SnippetsManager
    v-model:visible="showSnippetsManager"
  />

</template>

<script lang="ts" setup>
import {nextTick, onMounted, ref, toRaw, watch, onUnmounted, onBeforeUnmount} from "vue";
import {
  PlayCircleStrokeIcon, 
  SettingIcon, 
  AppIcon, 
  ShareIcon, 
  CodeIcon, 
  CallIcon, 
  MicrophoneIcon, 
  CallOffIcon
} from 'tdesign-icons-vue-next';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import loader from '@monaco-editor/loader';
// import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution'
import * as monaco from 'monaco-editor';
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import cssWorker from 'monaco-editor/esm/vs/language/css/css.worker?worker';
import htmlWorker from 'monaco-editor/esm/vs/language/html/html.worker?worker';
import tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker?worker';
import {useEditorStore} from '@/store/editor';
import RunDetails from "@/components/RunDetails.vue";
import EditorSettings from '@/components/EditorSettings.vue';
import {useEditorSettingsStore} from '@/store/editorSettings';
import { MessagePlugin } from 'tdesign-vue-next';
import AIExplainer from '@/components/AIExplainer.vue';
import ShareCode from '@/components/ShareCode.vue';
import TemplatesManager from '@/components/TemplatesManager.vue';
import SnippetsManager from '@/components/SnippetsManager.vue';
import { useSnippetsStore } from '@/store/snippets';
import { useUserStore } from '@/store/user';
import VoiceChat from '@/components/VoiceChat.vue';
import CodeEncouragement from '@/components/CodeEncouragement.vue';

// 配置 Monaco 编辑器的本地化
loader.config({
  'vs/nls': {
    availableLanguages: {
      '*': 'zh-cn'
    }
  }
});

const editorStore = useEditorStore();
const settingsStore = useEditorSettingsStore();
const snippetsStore = useSnippetsStore();
const userStore = useUserStore();

// 判题结果相关状态
const isSuccessful = ref(false);
const isError = ref(false);
const output = ref('');
const isLoading = ref(false);
const runInfo = ref<any[]>([]);
const drawerVisible = ref(false);

// Monaco 编辑器和其他必要的变量
const codeEditorRef = ref();
const codeEditor = ref();
const value = ref(editorStore.code || ''); // 初始化为 store 值
const value3 = ref(''); // 用于输入测试用例的变量

// 填充示例代码
const fillValue = () => {
  if (!codeEditor.value) {
    return;
  }
  // 改变编辑器中的内容
  toRaw(codeEditor.value).setValue(`#include<iostream>
using namespace std;
int main() {
    cout<<"Hello world!"<<endl;
    return 0;
}`);
};

const updateDrawerVisible = (newVisible: boolean) => {
  drawerVisible.value = newVisible;
};

const openRunDetails = () => {
  drawerVisible.value = true;
}

// 主题相关的状态
const currentTheme = ref('vs');
const themes = [
  { value: 'vs', label: '浅色主题' },
  { value: 'vs-dark', label: '深色主题' },
  { value: 'hc-black', label: '高对比度' }
];

// 语言相关的态
const languages = [
  { value: 'cpp', label: 'C++' },
  { value: 'c', label: 'C' },
  { value: 'java', label: 'Java' },
  { value: 'python', label: 'Python' },
  { value: 'javascript', label: 'JavaScript' },
  { value: 'typescript', label: 'TypeScript' },
  { value: 'go', label: 'Go' },
  { value: 'rust', label: 'Rust' },
  { value: 'csharp', label: 'C#' },
  { value: 'php', label: 'PHP' },
  { value: 'ruby', label: 'Ruby' },
  { value: 'swift', label: 'Swift' },
  { value: 'kotlin', label: 'Kotlin' },
  { value: 'scala', label: 'Scala' },
  { value: 'sql', label: 'SQL' },
];

const currentLanguage = ref('cpp');

// 监听主题变化
watch(currentTheme, async (newTheme) => {
  if (codeEditor.value) {
    const monacoInstance = await loader.init();
    monacoInstance.editor.setTheme(newTheme);
  }
});

// 配置 Monaco Editor 的 workers
window.MonacoEnvironment = {
  getWorker(_, label) {
    if (label === 'json') {
      return new jsonWorker();
    }
    if (label === 'css' || label === 'scss' || label === 'less') {
      return new cssWorker();
    }
    if (label === 'html' || label === 'handlebars' || label === 'razor') {
      return new htmlWorker();
    }
    if (label === 'typescript' || label === 'javascript') {
      return new tsWorker();
    }
    return new editorWorker();
  }
};

const completionProviders = ref<any[]>([]);

// 修改注册代码片段补全提供者的代码
const registerSnippetsCompletionProvider = async () => {
  // 清理已存在的补全提供者
  disposeCompletionProviders();
  
  const monacoInstance = await loader.init();
  
  // 为每种语言注册补全提供者
  const languages = ['cpp', 'javascript', 'typescript', 'python', 'java'];
  
  languages.forEach(language => {
    const provider = monacoInstance.languages.registerCompletionItemProvider(language, {
      provideCompletionItems: (model, position) => {
        const word = model.getWordUntilPosition(position);
        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn
        };

        return {
          suggestions: snippetsStore.snippets.map(snippet => ({
            label: snippet.trigger,
            kind: monacoInstance.languages.CompletionItemKind.Snippet,
            documentation: snippet.description,
            insertText: snippet.content,
            range: range,
            detail: '自定义代码片段'
          }))
        };
      }
    });
    
    completionProviders.value.push(provider);
  });
};

// 添加清理补全提供者的方法
const disposeCompletionProviders = () => {
  completionProviders.value.forEach(provider => provider?.dispose());
  completionProviders.value = [];
};

// 添加 resize 事件处理函数的引用
const handleEditorResize = () => {
  if (codeEditor.value) {
    const editor = toRaw(codeEditor.value);
    editor.layout();
  }
};

// 修改 onMounted 中的事件监听部分
onMounted(async () => {
  if (!codeEditorRef.value) return;

  try {
    const monacoInstance = await loader.init();
    
    // 设置初始主题
    monacoInstance.editor.setTheme(currentTheme.value);
    
    // 确保已经获取到代码片段
    if (userStore.isLoggedIn && snippetsStore.snippets.length === 0) {
      await snippetsStore.initialize();
    }
    
    // 创建编辑器实例
    codeEditor.value = monacoInstance.editor.create(codeEditorRef.value, {
      value: value.value,
      language: currentLanguage.value,
      automaticLayout: true,
      fontFamily: "Fira Code",
      fontSize: settingsStore.settings.fontSize,
      tabSize: settingsStore.settings.tabSize,
      wordWrap: settingsStore.settings.wordWrap ? 'on' : 'off',
      lineNumbers: settingsStore.settings.lineNumbers ? 'on' : 'off',
      minimap: {
        enabled: settingsStore.settings.minimap
      },
      fontLigatures: settingsStore.settings.fontLigatures,
      theme: currentTheme.value,
      suggestSelection: 'first',
      wordBasedSuggestions: 'matchingDocuments',
      quickSuggestions: true,
    });

    // 注册代码片段补全提供者
    await registerSnippetsCompletionProvider();

    // 添加快捷键打开代码片段管理器
    codeEditor.value.addAction({
      id: 'show-snippets-manager',
      label: '管理代码片段',
      keybindings: [
        monacoInstance.KeyMod.CtrlCmd | monacoInstance.KeyCode.KeyJ
      ],
      run: () => {
        showSnippetsManager.value = true;
      }
    });

    // 添加窗口大小变化的监听
    window.addEventListener('resize', handleEditorResize);
    
  } catch (error) {
    console.error('Monaco editor initialization failed:', error);
  }

  // 初始化代码高亮
  hljs.highlightAll();

  // 设置自动保存
  setupAutoSave();
});

// 主题切换方法
const changeTheme = (newTheme: string) => {
  currentTheme.value = newTheme;
};

// 语言切换方法
const changeLanguage = async (newLang: string) => {
  if (!codeEditor.value) return;
  
  // 更新编辑器语言
  const editor = toRaw(codeEditor.value);
  const model = editor.getModel();
  
  if (model) {
    monaco.editor.setModelLanguage(model, newLang);
  }

  if (newLang !== 'cpp') {
    output.value = '非C++语言目前仅支持代码高亮，暂不支持运行，持续开发中...';
    isSuccessful.value = false;
    isError.value = true;
  }
};

// 题逻
interface TestCase {
  input: string;
  expected?: string;
  output?: string;
  status?: string;
}

const testCases = ref<TestCase[]>([{ input: '', expected: '' }]);

const addTestCase = () => {
  testCases.value.push({ input: '', expected: '' });
};

const removeTestCase = (index: number) => {
  testCases.value.splice(index, 1);
};

const runCode = async () => {
  if (!codeEditor.value) {
    console.error("Code editor not initialized.");
    return;
  }

  // 检查当前语言
  if (currentLanguage.value !== 'cpp') {
    MessagePlugin.warning({
      content: '非C++语言目前仅支持码高亮，暂不支持运行，持续开发中...',
      duration: 3000,
      closeBtn: true,
    });
    return;
  }

  isLoading.value = true;
  const code = toRaw(codeEditor.value).getValue();
  
  try {
    const results = await Promise.all(
      testCases.value.map(testCase => 
        submitToJudgeSystem(code, testCase.input)
      )
    );
    
    runInfo.value = results;
    displayResults(results);
  } catch (error) {
    console.error("提交失败:", error);
    displayError(error);
  } finally {
    isLoading.value = false;
  }
};

// 向判题系统提交请求
const submitToJudgeSystem = async (code: string, input: string) => {
  const response = await fetch('https://panti.emoera.top/run.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code, // Monaco 编辑器中的代码
      input, // 用户输入的测试用例
    }),
  });

  if (!response.ok) {
    throw new Error('提交失败');
  }

  const result = await response.json();
  return result;
};

// 根据判题结果更新 UI 状态
enum Status {
  Accepted = 'Accepted', // 正常情况
  MemoryLimitExceeded = 'Memory Limit Exceeded', // 内存超限
  TimeLimitExceeded = 'Time Limit Exceeded', // 时间超限
  OutputLimitExceeded = 'Output Limit Exceeded', // 输出超限
  FileError = 'File Error', // 文件错误
  NonzeroExitStatus = 'Nonzero Exit Status', // 非 0 退出值
  Signalled = 'Signalled', // 进程被信号终止
  InternalError = 'Internal Error', // 内部错误
}

const getStatusText = (status: string) => {
  const statusMap: Record<string, string> = {
    'Accepted': '运行成功',
    'Memory Limit Exceeded': '内存超限',
    'Time Limit Exceeded': '时间限',
    'Output Limit Exceeded': '输出超限',
    'File Error': '文件错误',
    'NonzeroExitStatus': '运行异常',
    'Signalled': '进程被终止',
    'Internal Error': '内部错误'
  };
  return statusMap[status] || status;
};

const displayResults = (results: any[]) => {
  // 只要收到服务器返回的结果就显示运行成功
  isSuccessful.value = true;
  isError.value = false;
  
  output.value = results.map((result, index) => {
    const testCase = testCases.value[index];
    let outputText = `测试用例 #${index + 1}:\n`;
    
    if (result.status === Status.Accepted) {
      const actualOutput = (result.files?.stdout || '').trim();
      outputText += `状态: 通过\n`;
      
      // 如果有预期输出，进行对比
      if (testCase.expected && testCase.expected.trim()) {
        const expectedOutput = testCase.expected.trim();
        const isMatch = actualOutput === expectedOutput;
        
        outputText += isMatch 
          ? `结果: 答案正确 ✓\n` 
          : `结果: 答案错误 ✗\n`;
        
        if (!isMatch) {
          outputText += `预期输出:\n${expectedOutput}\n`;
          outputText += `实际输出:\n${actualOutput}\n`;
          outputText += `差异说明:\n`;
          
          if (actualOutput.length !== expectedOutput.length) {
            outputText += `- 输出长度不一致（预期: ${expectedOutput.length}, 实际: ${actualOutput.length}）\n`;
          }
          
          // 检查是否只是空白字符的差异
          const normalizedExpected = expectedOutput.replace(/\s+/g, ' ');
          const normalizedActual = actualOutput.replace(/\s+/g, ' ');
          if (normalizedExpected === normalizedActual) {
            outputText += `- 输出内容相同，但空白字符不一致\n`;
          }
          
          // 检查是否有多余或缺少的换行
          const expectedLines = expectedOutput.split('\n');
          const actualLines = actualOutput.split('\n');
          if (expectedLines.length !== actualLines.length) {
            outputText += `- 换行数量不一致（预期: ${expectedLines.length}, 实际: ${actualLines.length}）\n`;
          }
        }
      } else {
        outputText += `输出:\n${actualOutput}\n`;
      }
    } else {
      outputText += `状态: ${getStatusText(result.status)}\n`;
      if (result.files?.stderr) {
        outputText += `错误信息:\n${result.files.stderr}\n`;
      }
    }
    return outputText;
  }).join('\n---\n');
};

const displayError = (error: any) => {
  isError.value = true;
  isSuccessful.value = false;
  output.value = `请求失败: ${error.message || '未知错误'}`;
};

// 添加 isOutputMatch 函数
const isOutputMatch = (result: any, index: number) => {
  const testCase = testCases.value[index];
  if (!testCase?.expected) return true;
  const expectedOutput = testCase.expected.trim();
  const actualOutput = result.files?.stdout?.trim() || '';
  return expectedOutput === actualOutput;
};

const getAlertTheme = () => {
  if (!runInfo.value.length) return 'success';
  
  const totalCases = runInfo.value.length;
  const passedCases = runInfo.value.filter((result, index) => 
    result.status === 'Accepted' && isOutputMatch(result, index)
  ).length;
  
  if (passedCases === totalCases) return 'success';
  if (passedCases > 0) return 'warning';
  return 'error';
};

const getAlertMessage = () => {
  if (!runInfo.value.length) return '';
  
  const totalCases = runInfo.value.length;
  const passedCases = runInfo.value.filter((result, index) => 
    result.status === 'Accepted' && isOutputMatch(result, index)
  ).length;
  
  if (passedCases === totalCases) return '全部通过';
  if (passedCases > 0) return `部分通过（${passedCases}/${totalCases}）`;
  return '样例未通过';
};

// 添加设置相关的状态
const settingsVisible = ref(false);

// 添加以下方法
const openSettings = () => {
  settingsVisible.value = true;
};

const updateSettingsVisible = (newVisible: boolean) => {
  settingsVisible.value = newVisible;
};

// 添加 AI 解释相关的状态
const aiExplainerVisible = ref(false);

// 添加获取当前代码的方法
const getCurrentCode = () => {
  if (!codeEditor.value) return '';
  return toRaw(codeEditor.value).getValue();
};

// 添加打开 AI 解释器的方法
const openAIExplainer = () => {
  aiExplainerVisible.value = true;
};

// 添加更新 AI 解释器可见性的方法
const updateAIExplainerVisible = (newVisible: boolean) => {
  aiExplainerVisible.value = newVisible;
};

// 添加新的状态
const leftFlex = ref(7);
const rightFlex = ref(3);
const isResizing = ref(false);
const startX = ref(0);
const startLeftFlex = ref(0);

// 添加拖动相关方法
const startResize = (e: MouseEvent | TouchEvent) => {
  isResizing.value = true;
  startX.value = 'touches' in e ? e.touches[0].clientX : e.clientX;
  startLeftFlex.value = leftFlex.value;
  
  document.addEventListener('mousemove', handleResize);
  document.addEventListener('touchmove', handleResize);
  document.addEventListener('mouseup', stopResize);
  document.addEventListener('touchend', stopResize);
  
  // 添加禁止选择类
  document.body.classList.add('no-select');
};

const handleResize = (e: MouseEvent | TouchEvent) => {
  if (!isResizing.value) return;
  
  requestAnimationFrame(() => {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const containerWidth = document.querySelector('.editor-container')?.clientWidth || 1000;
    
    const movementRatio = (clientX - startX.value) / containerWidth;
    const newLeftFlex = Math.max(2, Math.min(8, startLeftFlex.value + movementRatio * 10));
    
    leftFlex.value = newLeftFlex;
    rightFlex.value = 10 - newLeftFlex;
    
    // 强制触发 Monaco Editor 重新布局
    if (codeEditor.value) {
      const editor = toRaw(codeEditor.value);
      editor.layout();
      
      // 添加一个小延时来确保布局更新
      setTimeout(() => {
        editor.layout();
      }, 10);
    }
  });
};

const stopResize = () => {
  isResizing.value = false;
  document.removeEventListener('mousemove', handleResize);
  document.removeEventListener('touchmove', handleResize);
  document.removeEventListener('mouseup', stopResize);
  document.removeEventListener('touchend', stopResize);
  
  document.body.classList.remove('no-select');
  
  // 最后一次布局更新
  if (codeEditor.value) {
    toRaw(codeEditor.value).layout();
  }
};

// 修改 onBeforeUnmount，确保正确清理所有资源
onBeforeUnmount(() => {
  // 清理补全提供者
  disposeCompletionProviders();
  
  // 清理编辑器实例
  if (codeEditor.value) {
    toRaw(codeEditor.value).dispose();
    codeEditor.value = null;
  }
  
  // 移除窗口大小变化的监听器
  window.removeEventListener('resize', handleEditorResize);
  
  // 清理自动保存定时器
  if (autoSaveInterval.value) {
    clearInterval(autoSaveInterval.value);
    autoSaveInterval.value = null;
  }
});

// 修改 onUnmounted，移除拖动相关的事件监听
onUnmounted(() => {
  // 移除拖动相关的事件监听
  document.removeEventListener('mousemove', handleResize);
  document.removeEventListener('touchmove', handleResize);
  document.removeEventListener('mouseup', stopResize);
  document.removeEventListener('touchend', stopResize);
  
  // 确保移除 no-select 类
  document.body.classList.remove('no-select');
});

// 添加自动保存功能
const autoSaveInterval = ref<number | null>(null);

// 在组件卸载前清除定时器
onBeforeUnmount(() => {
  // 清理补全提供者
  disposeCompletionProviders();
  
  // 清理编辑器实例
  if (codeEditor.value) {
    codeEditor.value.dispose();
  }
  
  // 移除窗口大小变化的监听器
  window.removeEventListener('resize', () => {
    if (codeEditor.value) {
      const editor = toRaw(codeEditor.value);
      editor.layout();
    }
  });
  
  // 清理自动保存定时器
  if (autoSaveInterval.value) {
    clearInterval(autoSaveInterval.value);
  }
});

// 在编辑器初始化后设置自动保存
const handleEditorMounted = (editor: any) => {
  // ... 现有的编辑器初始化代码 ...
  
  // 设置自动保存
  setupAutoSave();
};

const showSaveStatus = ref(false);

const showSavedStatus = () => {
  showSaveStatus.value = true;
  setTimeout(() => {
    showSaveStatus.value = false;
  }, 2000);
};

// 修改自动保存函数
const setupAutoSave = () => {
  autoSaveInterval.value = window.setInterval(() => {
    if (codeEditor.value) {
      const currentCode = toRaw(codeEditor.value).getValue();
      const previousCode = editorStore.code;
      if (currentCode !== previousCode) {
        editorStore.setCode(currentCode);
        showSavedStatus();
      }
    }
  }, 1000);
};

// 添加更新编辑器内容的方法
const updateEditorContent = (newCode: string) => {
  if (codeEditor.value) {
    const editor = toRaw(codeEditor.value);
    editor.setValue(newCode);
    editorStore.setCode(newCode);
  }
};

const showSnippetsManager = ref(false);

// 监听 snippets 变化时重新注册补全提供者
watch(() => snippetsStore.snippets, async () => {
  await registerSnippetsCompletionProvider();
}, { deep: true });

// 添加语音聊天相关的状态
const voiceChatVisible = ref(false);
const voiceChatRef = ref<InstanceType<typeof VoiceChat> | null>(null);
const isVoiceChatConnected = ref(false);
const isVoiceChatMuted = ref(false);
const voiceChatUserCount = ref(1);

// 添加打开语音聊天的方法
const openVoiceChat = () => {
  voiceChatVisible.value = true;
};

// 处理语音聊天连接状态变化
const handleVoiceChatConnected = () => {
  isVoiceChatConnected.value = true;
  voiceChatUserCount.value = 1; // 默认为1（自己）
  // 显示连接成功消息
  MessagePlugin.success('语音通话已连接');
};

const handleVoiceChatDisconnected = () => {
  isVoiceChatConnected.value = false;
  // 显示断开连接消息
  MessagePlugin.info('语音通话已断开');
};

// 处理语音聊天麦克风状态变化
const handleMuteChange = (muted: boolean) => {
  isVoiceChatMuted.value = muted;
  // 显示麦克风状态变化消息
  if (muted) {
    MessagePlugin.info('麦克风已静音');
  } else {
    MessagePlugin.success('麦克风已开启');
  }
};

// 切换麦克风状态
const toggleVoiceChatMute = () => {
  if (voiceChatRef.value) {
    voiceChatRef.value.toggleMute();
  }
};

// 离开语音聊天
const leaveVoiceChat = () => {
  if (voiceChatRef.value) {
    voiceChatRef.value.leaveChannel();
  }
};

// 添加用户数量变更处理函数
const handleUserCountChange = (count: number) => {
  const prevCount = voiceChatUserCount.value;
  voiceChatUserCount.value = count;
  
  // 当用户数变化时显示消息
  if (count > prevCount) {
    MessagePlugin.info(`新用户加入通话，当前${count}人`);
  } else if (count < prevCount && count > 0) {
    MessagePlugin.info(`有用户离开通话，当前${count}人`);
  }
};

</script>

<style scoped>
#code-editor {
  text-align: left; /* 确保文本左对齐 */
  margin: 0;
  padding: 0;
}

.bordered-div {
  border: 1px solid lightgray; /* 设置边框颜色和宽度 */
}

.custom-button {
  margin: 10px 0; /* 上下边距 */
}

/*右侧*/
.container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  padding: 0 10px;
  position: relative;
}

.scrollable-container {
  flex: 1;
  overflow-y: auto;
  padding-right: 8px;
  margin-bottom: 10px;
}

.test-cases-container {
  margin-bottom: 16px;
}

.test-case-item {
  margin-bottom: 12px;
  border: 1px solid #e5e6eb;
  border-radius: 6px;
  padding: 12px;
  background-color: #fff;
}

.output-section {
  margin-top: 16px;
}

.output-container {
  margin-top: 12px;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 12px;
}

.output-content {
  margin: 8px 0;
  padding: 12px;
  background-color: white;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Fira Code', monospace;
  font-size: 14px;
  line-height: 1.5;
  max-height: 200px;
  overflow-y: auto;
}

.action-buttons {
  display: flex;
  gap: 10px;
  padding: 10px 0;
  background-color: #fff;
  border-top: 1px solid #e5e6eb;
}

/* 自定义滚动样式 */
.scrollable-container::-webkit-scrollbar {
  width: 6px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #999;
}

@font-face {
  font-family: 'Fira Code';
  src: url('@/assets/fonts/FiraCode/FiraCode-Regular.woff2') format('woff2'),
  url('@/assets/fonts/FiraCode/FiraCode-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* 设置 Monaco Editor 使用 Fira Code 字体 */
.monaco-editor {
  font-family: 'Fira Code', monospace;
}

h4 {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.test-case-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.test-case-header span {
  font-weight: 500;
  color: #666;
}

.test-case-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.success-case {
  background-color: rgba(82, 196, 26, 0.1);
}

.error-case {
  background-color: rgba(227, 77, 89, 0.1);
}

.input-section,
.expected-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-label {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.error-text {
  color: var(--td-error-color);
}

.actual-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-top: 1px dashed var(--td-error-color-1);
}

.editor-container {
  position: relative;
  display: flex;
  width: 100%;
  gap: 0;
  height: 80vh;
}

.editor-section {
  position: relative;
  min-width: 0; /* 防止内容溢出 */
  transition: flex 0s;
}

.right-section {
  position: relative;
  min-width: 0;
  transition: flex 0s;
}

.resizer {
  width: 8px;
  cursor: col-resize;
  background: transparent;
  position: relative;
  z-index: 1;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none;
  will-change: transform;
  flex: none;
}

.resizer-line {
  width: 2px;
  height: 100%;
  background-color: var(--td-component-border);
  transition: background-color 0.2s;
}

.resizer:hover .resizer-line,
.resizer:active .resizer-line {
  background-color: var(--td-brand-color);
}

/* 添加全局样式 */
:global(.no-select) {
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}

.t-col {
  transition: none; /* 移除可能的过渡效果 */
  will-change: flex; /* 提示浏览器 flex 值将会改变 */
}

.save-status {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.encouragement-component {
  margin-right: 8px;
  min-width: 200px;
  max-width: 380px;
  flex-grow: 1; /* 允许组件占用更多空间 */
  position: relative; /* 添加相对定位 */
  z-index: 6; /* 增加z-index确保提示框显示在编辑器上方 */
}

.call-controls {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #fefefe, #f5f7f9);
  border-radius: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(229, 230, 235, 0.7);
  overflow: hidden;
  margin-right: 12px;
  height: 48px;
  animation: fadeIn 0.3s ease-in-out;
}

.call-indicator {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 100%;
  background-color: rgba(0, 170, 114, 0.1);
  border-right: 1px solid rgba(0, 170, 114, 0.2);
}

.call-indicator span {
  font-size: 13px;
  font-weight: 500;
  color: #00aa72;
  margin-left: 8px;
}

.pulse-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #00aa72;
  animation: pulse 1.5s infinite;
}

.call-buttons {
  display: flex;
  align-items: center;
  height: 100%;
}

.voice-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  width: 48px;
  padding: 0;
  transition: background-color 0.2s;
}

.voice-btn:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.btn-icon-wrapper {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mic-btn {
  color: #00aa72;
}

.mic-btn.muted {
  color: #ea4335;
}

.mic-icon {
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'%3E%3Cpath d='M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z'/%3E%3Cpath d='M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.mute-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  transform: translateY(-50%) rotate(45deg);
}

.hangup-btn {
  color: #ea4335;
}

.hangup-icon {
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'%3E%3Cpath d='M12 9c-1.6 0-3.15.25-4.6.72v3.1c0 .39-.23.74-.56.9-.98.49-1.87 1.12-2.66 1.85-.18.18-.43.28-.7.28-.28 0-.53-.11-.71-.29L.29 13.08c-.18-.17-.29-.42-.29-.7 0-.28.11-.53.29-.71C3.34 8.78 7.46 7 12 7s8.66 1.78 11.71 4.67c.18.18.29.43.29.71 0 .28-.11.53-.29.71l-2.48 2.48c-.18.18-.43.29-.71.29-.27 0-.52-.1-.7-.28-.79-.73-1.68-1.36-2.66-1.85-.33-.16-.56-.5-.56-.9v-3.1C15.15 9.25 13.6 9 12 9z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.voice-chat-btn {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #fcf0e6, #fff8f0);
  color: #ff9500;
  border: 1px solid rgba(255, 149, 0, 0.2);
  border-radius: 24px;
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  outline: none;
}

.voice-chat-btn:hover {
  background: linear-gradient(to right, #fcf0e6, #ffeed8);
  box-shadow: 0 2px 6px rgba(255, 149, 0, 0.2);
}

.voice-chat-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff9500'%3E%3Cpath d='M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 170, 114, 0.4);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(0, 170, 114, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 170, 114, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
