import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ai-content" }
const _hoisted_2 = {
  id: "chatLog",
  class: "chat-log"
}
const _hoisted_3 = {
  key: 0,
  class: "initial-state"
}
const _hoisted_4 = { class: "message-avatar" }
const _hoisted_5 = { class: "message-content" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 1,
  class: "message-container ai-message"
}
const _hoisted_10 = { class: "input-container" }
const _hoisted_11 = { class: "input-actions" }
const _hoisted_12 = { class: "input-group" }

import { ref, onBeforeUnmount, computed, reactive } from 'vue';
import { MessagePlugin, DialogPlugin } from 'tdesign-vue-next';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { useEditorStore } from '@/store/editor';

// 配置 marked
interface ChatMessage {
  sender: 'user' | 'ai';
  content: string;
  renderedContent?: string;
  think?: string | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AIExplainer',
  props: {
  visible: Boolean,
  code: String,
},
  emits: ['update:visible'],
  setup(__props, { emit: __emit }) {

marked.setOptions({
  gfm: true,
  breaks: true,
  pedantic: false
});

const props = __props;

const emit = __emit;
const isLoading = ref(false);
const userInput = ref('');
const chatHistory = ref<ChatMessage[]>([]);
const streamController = ref<AbortController | null>(null);
const thinkVisible = reactive<Record<number, boolean>>({});
const inputFocused = ref(false);
const includeCode = ref(false);

const editorStore = useEditorStore();

// 从localStorage加载历史记录
const loadHistory = () => {
  try {
    const savedHistory = localStorage.getItem('aiChatHistory');
    if (savedHistory) {
      const history = JSON.parse(savedHistory) as ChatMessage[];
      // 为AI消息添加渲染后的内容
      history.forEach(msg => {
        if (msg.sender === 'ai') {
          msg.renderedContent = DOMPurify.sanitize(marked.parse(msg.content));
        }
      });
      chatHistory.value = history;
      // 初始化思维链显示状态
      history.forEach((_, index) => {
        thinkVisible[index] = true;
      });
    }
  } catch (e) {
    console.error('加载聊天历史失败:', e);
  }
};

// 保存历史记录到localStorage
const saveHistory = () => {
  try {
    localStorage.setItem('aiChatHistory', JSON.stringify(chatHistory.value));
  } catch (e) {
    console.error('保存聊天历史失败:', e);
  }
};

// 切换思维链显示状态
const toggleThink = (index: number) => {
  thinkVisible[index] = !thinkVisible[index];
};

const handleClose = () => {
  emit('update:visible', false);
};

const handleKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    if (e.shiftKey) {
      // Shift + Enter 换行，保持默认行为
      return;
    } else {
      // 仅 Enter 发送消息
      e.preventDefault();
      sendMessage();
    }
  }
};

const scrollToBottom = () => {
  setTimeout(() => {
    const chatLogElement = document.getElementById('chatLog');
    if (chatLogElement) {
      chatLogElement.scrollTop = chatLogElement.scrollHeight;
    }
  }, 50);
};

const sendMessage = async () => {
  const text = userInput.value.trim();
  if ((!text && !includeCode.value) || isLoading.value) return;

  // 准备发送内容
  let messageContent = text;
  
  // 如果需要包含代码，添加代码块
  if (includeCode.value) {
    const currentCode = props.code || editorStore.code || '';
    const codeBlock = `\`\`\`\n${currentCode}\n\`\`\`\n`;
    
    if (messageContent) {
      // 将代码放在用户输入的上方，而不是下方
      messageContent = `${codeBlock}\n\n${messageContent}`;
    } else {
      messageContent = codeBlock;
    }
    
    // 重置状态
    includeCode.value = false;
  }

  // 添加用户消息到历史
  chatHistory.value.push({
    sender: 'user',
    content: messageContent
  });
  
  userInput.value = '';
  isLoading.value = true;
  scrollToBottom();
  
  if (streamController.value) {
    streamController.value.abort();
  }
  
  streamController.value = new AbortController();
  
  try {
    // 准备请求数据
    const requestData = {
      prompt: messageContent,
      history: chatHistory.value.slice(0, -1) // 不包含最新的用户消息
    };
    
    const response = await fetch('https://aiapiv3.emoera.top/stream', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
      signal: streamController.value.signal
    });

    if (!response.ok) {
      throw new Error('网络请求失败');
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('无法读取响应流');
    }

    // 创建AI回复的消息对象
    let accumulatedContent = "";
    let reasoningContent = "";
    
    // 添加一个空的AI回复到历史
    chatHistory.value.push({
      sender: 'ai',
      content: '',
      renderedContent: '',
      think: null
    });
    
    const aiMessageIndex = chatHistory.value.length - 1;
    thinkVisible[aiMessageIndex] = true;
    
    const decoder = new TextDecoder();
    
    for (;;) {
      const { done, value } = await reader.read();
      if (done) break;
      
      const text = decoder.decode(value);
      const lines = text.split('\n');
      
      for (const line of lines) {
        if (!line.trim()) continue;
        
        if (line.startsWith('data: ')) {
          const data = line.slice(6);
          if (data === '[DONE]') continue;
          
          try {
            const jsonData = JSON.parse(data);
            
            // 处理思维链内容
            if (jsonData.choices[0].delta.reasoning_content) {
              const content = jsonData.choices[0].delta.reasoning_content;
              const cleanContent = content.replace(/<\/?think>/gi, '');
              if (cleanContent.trim()) {
                reasoningContent += cleanContent;
                chatHistory.value[aiMessageIndex].think = reasoningContent;
              }
            }
            
            // 处理主要内容
            if (jsonData.choices[0].delta.content) {
              const content = jsonData.choices[0].delta.content;
              accumulatedContent += content;
              chatHistory.value[aiMessageIndex].content = accumulatedContent;
              chatHistory.value[aiMessageIndex].renderedContent = DOMPurify.sanitize(marked.parse(accumulatedContent));
              scrollToBottom();
            }
          } catch (e) {
            if (e instanceof SyntaxError) {
              console.debug('跳过不完整的JSON数据:', line);
              continue;
            }
            throw e;
          }
        }
      }
    }
    
    // 保存历史记录
    saveHistory();
  } catch (err: any) {
    if (err.name === 'AbortError') {
      console.log('请求被中止');
      return;
    }
    const errorMessage = err.message || '请求失败，请稍后重试';
    MessagePlugin.error(errorMessage);
    
    // 如果已经添加了AI消息但失败了，更新为错误消息
    if (chatHistory.value.length > 0 && chatHistory.value[chatHistory.value.length - 1].sender === 'ai') {
      chatHistory.value[chatHistory.value.length - 1].content = `请求出错: ${errorMessage}`;
      chatHistory.value[chatHistory.value.length - 1].renderedContent = DOMPurify.sanitize(marked.parse(`请求出错: ${errorMessage}`));
    } else {
      // 否则添加一个新的错误消息
      chatHistory.value.push({
        sender: 'ai',
        content: `请求出错: ${errorMessage}`,
        renderedContent: DOMPurify.sanitize(marked.parse(`请求出错: ${errorMessage}`)),
        think: null
      });
    }
    saveHistory();
  } finally {
    isLoading.value = false;
    streamController.value = null;
  }
};

// 插入当前代码到输入框
const insertCurrentCode = () => {
  const currentCode = props.code || editorStore.code || '';
  if (!currentCode.trim()) {
    MessagePlugin.warning('当前没有可插入的代码');
    return;
  }
  
  // 切换状态而不是直接修改输入框
  includeCode.value = !includeCode.value;
};

// 清除历史记录
const clearHistory = () => {
  chatHistory.value = [];
  saveHistory();
  MessagePlugin.success('聊天历史已清空');
};

// 组件挂载时加载历史记录
loadHistory();

onBeforeUnmount(() => {
  if (streamController.value) {
    streamController.value.abort();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_t_empty = _resolveComponent("t-empty")!
  const _component_t_icon = _resolveComponent("t-icon")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_textarea = _resolveComponent("t-textarea")!
  const _component_t_drawer = _resolveComponent("t-drawer")!

  return (_openBlock(), _createBlock(_component_t_drawer, {
    visible: __props.visible,
    size: '50%',
    header: "AI助手",
    onClose: handleClose,
    closeOnOverlayClick: true,
    showOverlay: true,
    "size-draggable": true
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_t_button, {
            theme: "default",
            size: "small",
            onClick: insertCurrentCode,
            class: _normalizeClass({ 'code-button-active': includeCode.value })
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_t_icon, { name: "code" })
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(includeCode.value ? '已选择代码' : '插入当前代码'), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_t_button, {
            theme: "default",
            size: "small",
            onClick: clearHistory
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_t_icon, { name: "clear" })
            ]),
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createTextVNode(" 清空历史 "))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_t_textarea, {
            modelValue: userInput.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((userInput).value = $event)),
            class: "form-control",
            placeholder: "请输入您的问题...",
            autosize: { minRows: 1, maxRows: 5 },
            onKeydown: handleKeyDown,
            onFocus: _cache[1] || (_cache[1] = ($event: any) => (inputFocused.value = true)),
            onBlur: _cache[2] || (_cache[2] = ($event: any) => (inputFocused.value = false))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_t_button, {
            theme: "primary",
            onClick: sendMessage,
            loading: isLoading.value,
            disabled: isLoading.value || !userInput.value.trim(),
            class: "send-button"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_t_icon, { name: "send" })
            ]),
            _: 1
          }, 8, ["loading", "disabled"])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "input-tips" }, [
          _createElementVNode("span", null, "DeepSeek R1 模型")
        ], -1))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (chatHistory.value.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_t_empty, {
                  description: "在下方输入问题开始与AI对话",
                  icon: "chat"
                })
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chatHistory.value, (message, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["message-container", message.sender === 'user' ? 'user-message' : 'ai-message'])
            }, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(message.sender === 'user' ? '你' : 'AI'), 1),
              _createElementVNode("div", _hoisted_5, [
                (message.sender === 'ai' && message.think)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", {
                        class: "think-toggle",
                        onClick: ($event: any) => (toggleThink(index))
                      }, " 💭 " + _toDisplayString(thinkVisible[index] ? '隐藏思维过程' : '显示思维过程'), 9, _hoisted_6),
                      _createElementVNode("div", {
                        class: "think-block",
                        style: _normalizeStyle({ display: thinkVisible[index] ? 'block' : 'none' })
                      }, _toDisplayString(message.think), 5)
                    ], 64))
                  : _createCommentVNode("", true),
                (message.sender === 'user')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "markdown-content",
                      textContent: _toDisplayString(message.content)
                    }, null, 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "markdown-content",
                      innerHTML: message.renderedContent
                    }, null, 8, _hoisted_8))
              ])
            ], 2))
          }), 128)),
          (isLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[3] || (_cache[3] = [
                _createElementVNode("div", { class: "message-avatar" }, "AI", -1),
                _createElementVNode("div", { class: "message-content" }, [
                  _createElementVNode("div", { class: "markdown-content typing-animation" })
                ], -1)
              ])))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})