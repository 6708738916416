<template>
  <div class="encouragement-container">
    <div v-if="message" class="message-wrapper" @mouseenter="startScroll" @mouseleave="stopScroll">
      <div class="icon">✨</div>
      <div class="message-container" @click="toggleTooltip">
        <div class="message" ref="messageRef" :class="{'scrollable': isOverflowing}">
          {{ message }}
          <span v-if="isOverflowing" class="message-indicator">⟺</span>
        </div>
        <div class="gradient-right" v-if="isOverflowing"></div>
      </div>
      <div class="tooltip" v-if="(isOverflowing && isHovering) || showTooltip">
        <div class="tooltip-content">{{ message }}</div>
        <div class="tooltip-footer" v-if="showTooltip">
          <span class="tooltip-close" @click.stop="closeTooltip">关闭</span>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading" class="message-wrapper loading">
      <div class="icon">✨</div>
      <div class="message typing-animation"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount, watch, defineProps, nextTick } from 'vue';
import { MessagePlugin } from 'tdesign-vue-next';

const props = defineProps({
  code: {
    type: String,
    default: ''
  }
});

const message = ref('');
const isLoading = ref(false);
const updateInterval = ref<number | null>(null);
const debounceTimer = ref<number | null>(null);
const messageRef = ref<HTMLElement | null>(null);
const isOverflowing = ref(false);
const isHovering = ref(false);
const scrollTimer = ref<number | null>(null);
const showTooltip = ref(false);
const tooltipTimer = ref<number | null>(null);

// 点击切换tooltip显示
const toggleTooltip = () => {
  if (isOverflowing.value) {
    showTooltip.value = !showTooltip.value;
    
    // 如果显示了tooltip，设置定时器5秒后自动隐藏
    if (showTooltip.value) {
      if (tooltipTimer.value) {
        clearTimeout(tooltipTimer.value);
      }
      tooltipTimer.value = window.setTimeout(() => {
        showTooltip.value = false;
        tooltipTimer.value = null;
      }, 5000);
    } else if (tooltipTimer.value) {
      clearTimeout(tooltipTimer.value);
      tooltipTimer.value = null;
    }
  }
};

// 检查文本是否溢出
const checkOverflow = () => {
  nextTick(() => {
    if (messageRef.value) {
      isOverflowing.value = messageRef.value.scrollWidth > messageRef.value.clientWidth;
    }
  });
};

// 开始滚动
const startScroll = () => {
  isHovering.value = true;
  
  // 如果文本溢出且尚未开始滚动，则开始滚动
  if (isOverflowing.value && !scrollTimer.value && messageRef.value) {
    // 先暂停可能已经进行的动画
    if (messageRef.value.style.transform !== 'translateX(0px)') {
      messageRef.value.style.transition = 'none';
      messageRef.value.style.transform = 'translateX(0)';
      // 强制重绘
      void messageRef.value.offsetWidth;
    }
    
    const contentWidth = messageRef.value.scrollWidth;
    const containerWidth = messageRef.value.clientWidth;
    const scrollDistance = contentWidth - containerWidth;
    
    // 只有当需要滚动的距离大于10像素才滚动
    if (scrollDistance <= 10) return;
    
    // 滚动速度: 每像素15ms，最小2秒，最大6秒
    const duration = Math.min(Math.max(scrollDistance * 15, 2000), 6000);
    
    // 先有短暂停顿
    setTimeout(() => {
      if (messageRef.value) {
        messageRef.value.style.transition = `transform ${duration}ms cubic-bezier(0.3, 0.0, 0.3, 1.0)`;
        messageRef.value.style.transform = `translateX(-${scrollDistance}px)`;
        
        // 滚动结束后重置位置
        scrollTimer.value = window.setTimeout(() => {
          if (messageRef.value) {
            messageRef.value.style.transition = 'transform 500ms ease-in-out';
            messageRef.value.style.transform = 'translateX(0)';
            scrollTimer.value = null;
          }
        }, duration + 500);
      }
    }, 300);
  }
};

// 停止滚动
const stopScroll = () => {
  isHovering.value = false;
  
  // 不立即隐藏tooltip，给用户一些时间点击
  setTimeout(() => {
    if (!showTooltip.value) {
      if (messageRef.value) {
        messageRef.value.style.transition = 'transform 500ms ease-in-out';
        messageRef.value.style.transform = 'translateX(0)';
      }
    }
  }, 300);
  
  if (scrollTimer.value) {
    clearTimeout(scrollTimer.value);
    scrollTimer.value = null;
  }
};

// 防抖函数
const debounce = (fn: () => void, delay: number) => {
  if (debounceTimer.value) {
    clearTimeout(debounceTimer.value);
  }
  debounceTimer.value = window.setTimeout(() => {
    fn();
    debounceTimer.value = null;
  }, delay);
};

// 监听代码变化
watch(() => props.code, (newCode) => {
  if (newCode && newCode.trim() !== '') {
    // 使用防抖函数，延迟2秒执行
    debounce(getEncouragement, 2000);
  }
}, { deep: true });

// 监听消息变化，检查是否溢出，并添加自动滚动
watch(() => message.value, (newMessage) => {
  if (newMessage) {
    checkOverflow();
    // 如果文本溢出，自动滚动一次
    nextTick(() => {
      if (isOverflowing.value) {
        autoScrollOnce();
      }
    });
  }
});

// 获取鼓励消息
const getEncouragement = async () => {
  if (!props.code || props.code.trim() === '' || isLoading.value) {
    return;
  }

  isLoading.value = true;

  try {
    // 准备请求数据
    const requestData = {
      prompt: "基于所提供代码的功能，用一句话给写代码的人鼓励与肯定。\n\n```\n" + props.code + "\n```",
      history: [] // 无历史对话
    };
    
    const response = await fetch('https://aiapiv3.emoera.top/stream', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error('网络请求失败');
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('无法读取响应流');
    }
    
    // 创建解码器和积累内容的变量
    const decoder = new TextDecoder();
    let accumulatedContent = "";
    
    // 读取流
    for (;;) {
      const { done, value } = await reader.read();
      if (done) break;
      
      const text = decoder.decode(value);
      const lines = text.split('\n');
      
      for (const line of lines) {
        if (!line.trim()) continue;
        
        if (line.startsWith('data: ')) {
          const data = line.slice(6);
          if (data === '[DONE]') continue;
          
          try {
            const jsonData = JSON.parse(data);
            
            // 处理主要内容，不处理思维链
            if (jsonData.choices && jsonData.choices[0] && jsonData.choices[0].delta && jsonData.choices[0].delta.content) {
              accumulatedContent += jsonData.choices[0].delta.content;
            }
          } catch (e) {
            console.debug('跳过不完整的JSON数据:', line);
          }
        }
      }
    }
    
    // 设置最终消息
    if (accumulatedContent) {
      // 去除可能的引号和多余标点
      message.value = accumulatedContent.replace(/^["']|["']$/g, '').trim();
    }
  } catch (error) {
    console.error('获取鼓励消息失败:', error);
    // 出错时不显示错误，保持上一次的消息
  } finally {
    isLoading.value = false;
  }
};

// 设置定时更新
const setupInterval = () => {
  // 只有当编辑器有内容时才立即执行
  if (props.code && props.code.trim() !== '') {
    getEncouragement();
  }
  
  // 每分钟更新一次
  updateInterval.value = window.setInterval(() => {
    if (props.code && props.code.trim() !== '') {
      getEncouragement();
    }
  }, 60000); // 60秒 = 1分钟
};

// 自动滚动一次的函数
const autoScrollOnce = () => {
  if (!messageRef.value || scrollTimer.value) return;
  
  // 先暂停可能已经进行的动画
  if (messageRef.value.style.transform !== 'translateX(0px)') {
    messageRef.value.style.transition = 'none';
    messageRef.value.style.transform = 'translateX(0)';
    // 强制重绘
    void messageRef.value.offsetWidth;
  }
  
  const contentWidth = messageRef.value.scrollWidth;
  const containerWidth = messageRef.value.clientWidth;
  const scrollDistance = contentWidth - containerWidth;
  
  // 只有当需要滚动的距离大于10像素才滚动
  if (scrollDistance <= 10) return;
  
  // 自动滚动速度比悬停滚动稍快
  const duration = Math.min(Math.max(scrollDistance * 12, 1500), 5000);
  
  // 短暂延迟后开始滚动
  setTimeout(() => {
    if (messageRef.value) {
      messageRef.value.style.transition = `transform ${duration}ms cubic-bezier(0.3, 0.0, 0.3, 1.0)`;
      messageRef.value.style.transform = `translateX(-${scrollDistance}px)`;
      
      // 滚动结束后重置位置
      scrollTimer.value = window.setTimeout(() => {
        if (messageRef.value) {
          messageRef.value.style.transition = 'transform 500ms ease-in-out';
          messageRef.value.style.transform = 'translateX(0)';
          scrollTimer.value = null;
        }
      }, duration + 300);
    }
  }, 800); // 延迟稍长，让用户有时间注意到新消息
};

// 关闭tooltip
const closeTooltip = () => {
  showTooltip.value = false;
  if (tooltipTimer.value) {
    clearTimeout(tooltipTimer.value);
    tooltipTimer.value = null;
  }
};

onMounted(() => {
  setupInterval();
});

onBeforeUnmount(() => {
  if (updateInterval.value) {
    clearInterval(updateInterval.value);
    updateInterval.value = null;
  }
  
  if (scrollTimer.value) {
    clearTimeout(scrollTimer.value);
    scrollTimer.value = null;
  }
  
  if (tooltipTimer.value) {
    clearTimeout(tooltipTimer.value);
    tooltipTimer.value = null;
  }
});
</script>

<style scoped>
.encouragement-container {
  display: flex;
  align-items: center;
  max-width: 380px;
  transition: all 0.3s ease;
}

.message-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  background: linear-gradient(to right, #eef6ff, #f7faff);
  padding: 0 16px;
  border-radius: 24px;
  border: 1px solid rgba(64, 158, 255, 0.2);
  box-shadow: 0 2px 6px rgba(24, 144, 255, 0.05);
  height: 40px;
  overflow: visible;
  animation: fadeIn 0.5s ease-in-out;
  min-width: 200px;
  position: relative;
  z-index: 10;
}

.message-wrapper:hover {
  border-color: rgba(64, 158, 255, 0.4);
  box-shadow: 0 3px 8px rgba(24, 144, 255, 0.08);
}

.message-container {
  overflow: hidden;
  max-width: 320px;
  position: relative;
  cursor: pointer;
  padding: 0 2px;
}

.message-container:hover .gradient-right {
  opacity: 0.4;
}

.message {
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  overflow: visible;
  line-height: 1.5;
  transition: transform 0.5s ease-in-out;
  padding-right: 10px;
}

.message.scrollable {
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(8px) scale(0.95);
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 14px 16px;
  border-radius: 12px;
  font-size: 13px;
  z-index: 9999;
  white-space: normal;
  word-wrap: break-word;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
  line-height: 1.6;
  pointer-events: auto;
  opacity: 0;
  animation: fadeInTooltip 0.3s forwards;
  max-height: 300px;
  overflow-y: auto;
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  transform-origin: top center;
  box-sizing: border-box;
}

/* 自定义滚动条样式 */
.tooltip::-webkit-scrollbar {
  width: 6px;
}

.tooltip::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 3px;
}

.tooltip::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.tooltip::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

.tooltip-content {
  letter-spacing: 0.3px;
  font-weight: 300;
}

.tooltip-footer {
  margin-top: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 10px;
  text-align: center;
  font-size: 12px;
}

.tooltip-close {
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  padding: 5px 14px;
  border-radius: 20px;
  transition: all 0.2s;
  background-color: rgba(255, 255, 255, 0.12);
  display: inline-block;
  font-weight: 500;
}

.tooltip-close:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.25);
  transform: scale(1.05);
}

.loading {
  background: linear-gradient(to right, #f8f8f8, #f0f0f0);
  border-color: rgba(200, 200, 200, 0.4);
}

.loading .message {
  color: #666;
}

.icon {
  font-size: 16px;
  flex-shrink: 0;
  color: #1890ff;
  animation: twinkle 2s infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes twinkle {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.1);
  }
}

/* 添加打字机效果 */
@keyframes typing {
  0% { content: "AI正在思考"; }
  25% { content: "AI正在思考."; }
  50% { content: "AI正在思考.."; }
  75% { content: "AI正在思考..."; }
}

.typing-animation::after {
  content: "AI正在思考";
  animation: typing 1.5s infinite;
  color: #666;
}

.gradient-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
  background: linear-gradient(to right, rgba(238, 246, 255, 0), rgba(238, 246, 255, 1));
  pointer-events: none;
}

.loading .gradient-right {
  background: linear-gradient(to right, rgba(248, 248, 248, 0), rgba(248, 248, 248, 1));
}

@keyframes fadeInTooltip {
  from {
    opacity: 0;
    transform: translateY(8px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* 修改滚动效果的样式，添加更微妙的指示器 */
.message-indicator {
  display: inline-block;
  margin-left: 4px;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s;
}

.message.scrollable .message-indicator {
  opacity: 0.5;
}

.message.scrollable:hover .message-indicator {
  opacity: 0.8;
}
</style> 