import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "encouragement-container" }
const _hoisted_2 = {
  key: 0,
  class: "message-indicator"
}
const _hoisted_3 = {
  key: 0,
  class: "gradient-right"
}
const _hoisted_4 = {
  key: 0,
  class: "tooltip"
}
const _hoisted_5 = { class: "tooltip-content" }
const _hoisted_6 = {
  key: 0,
  class: "tooltip-footer"
}
const _hoisted_7 = {
  key: 1,
  class: "message-wrapper loading"
}

import { ref, onMounted, onBeforeUnmount, watch, nextTick } from 'vue';
import { MessagePlugin } from 'tdesign-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'CodeEncouragement',
  props: {
  code: {
    type: String,
    default: ''
  }
},
  setup(__props) {

const props = __props;

const message = ref('');
const isLoading = ref(false);
const updateInterval = ref<number | null>(null);
const debounceTimer = ref<number | null>(null);
const messageRef = ref<HTMLElement | null>(null);
const isOverflowing = ref(false);
const isHovering = ref(false);
const scrollTimer = ref<number | null>(null);
const showTooltip = ref(false);
const tooltipTimer = ref<number | null>(null);

// 点击切换tooltip显示
const toggleTooltip = () => {
  if (isOverflowing.value) {
    showTooltip.value = !showTooltip.value;
    
    // 如果显示了tooltip，设置定时器5秒后自动隐藏
    if (showTooltip.value) {
      if (tooltipTimer.value) {
        clearTimeout(tooltipTimer.value);
      }
      tooltipTimer.value = window.setTimeout(() => {
        showTooltip.value = false;
        tooltipTimer.value = null;
      }, 5000);
    } else if (tooltipTimer.value) {
      clearTimeout(tooltipTimer.value);
      tooltipTimer.value = null;
    }
  }
};

// 检查文本是否溢出
const checkOverflow = () => {
  nextTick(() => {
    if (messageRef.value) {
      isOverflowing.value = messageRef.value.scrollWidth > messageRef.value.clientWidth;
    }
  });
};

// 开始滚动
const startScroll = () => {
  isHovering.value = true;
  
  // 如果文本溢出且尚未开始滚动，则开始滚动
  if (isOverflowing.value && !scrollTimer.value && messageRef.value) {
    // 先暂停可能已经进行的动画
    if (messageRef.value.style.transform !== 'translateX(0px)') {
      messageRef.value.style.transition = 'none';
      messageRef.value.style.transform = 'translateX(0)';
      // 强制重绘
      void messageRef.value.offsetWidth;
    }
    
    const contentWidth = messageRef.value.scrollWidth;
    const containerWidth = messageRef.value.clientWidth;
    const scrollDistance = contentWidth - containerWidth;
    
    // 只有当需要滚动的距离大于10像素才滚动
    if (scrollDistance <= 10) return;
    
    // 滚动速度: 每像素15ms，最小2秒，最大6秒
    const duration = Math.min(Math.max(scrollDistance * 15, 2000), 6000);
    
    // 先有短暂停顿
    setTimeout(() => {
      if (messageRef.value) {
        messageRef.value.style.transition = `transform ${duration}ms cubic-bezier(0.3, 0.0, 0.3, 1.0)`;
        messageRef.value.style.transform = `translateX(-${scrollDistance}px)`;
        
        // 滚动结束后重置位置
        scrollTimer.value = window.setTimeout(() => {
          if (messageRef.value) {
            messageRef.value.style.transition = 'transform 500ms ease-in-out';
            messageRef.value.style.transform = 'translateX(0)';
            scrollTimer.value = null;
          }
        }, duration + 500);
      }
    }, 300);
  }
};

// 停止滚动
const stopScroll = () => {
  isHovering.value = false;
  
  // 不立即隐藏tooltip，给用户一些时间点击
  setTimeout(() => {
    if (!showTooltip.value) {
      if (messageRef.value) {
        messageRef.value.style.transition = 'transform 500ms ease-in-out';
        messageRef.value.style.transform = 'translateX(0)';
      }
    }
  }, 300);
  
  if (scrollTimer.value) {
    clearTimeout(scrollTimer.value);
    scrollTimer.value = null;
  }
};

// 防抖函数
const debounce = (fn: () => void, delay: number) => {
  if (debounceTimer.value) {
    clearTimeout(debounceTimer.value);
  }
  debounceTimer.value = window.setTimeout(() => {
    fn();
    debounceTimer.value = null;
  }, delay);
};

// 监听代码变化
watch(() => props.code, (newCode) => {
  if (newCode && newCode.trim() !== '') {
    // 使用防抖函数，延迟2秒执行
    debounce(getEncouragement, 2000);
  }
}, { deep: true });

// 监听消息变化，检查是否溢出，并添加自动滚动
watch(() => message.value, (newMessage) => {
  if (newMessage) {
    checkOverflow();
    // 如果文本溢出，自动滚动一次
    nextTick(() => {
      if (isOverflowing.value) {
        autoScrollOnce();
      }
    });
  }
});

// 获取鼓励消息
const getEncouragement = async () => {
  if (!props.code || props.code.trim() === '' || isLoading.value) {
    return;
  }

  isLoading.value = true;

  try {
    // 准备请求数据
    const requestData = {
      prompt: "基于所提供代码的功能，用一句话给写代码的人鼓励与肯定。\n\n```\n" + props.code + "\n```",
      history: [] // 无历史对话
    };
    
    const response = await fetch('https://aiapiv3.emoera.top/stream', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error('网络请求失败');
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('无法读取响应流');
    }
    
    // 创建解码器和积累内容的变量
    const decoder = new TextDecoder();
    let accumulatedContent = "";
    
    // 读取流
    for (;;) {
      const { done, value } = await reader.read();
      if (done) break;
      
      const text = decoder.decode(value);
      const lines = text.split('\n');
      
      for (const line of lines) {
        if (!line.trim()) continue;
        
        if (line.startsWith('data: ')) {
          const data = line.slice(6);
          if (data === '[DONE]') continue;
          
          try {
            const jsonData = JSON.parse(data);
            
            // 处理主要内容，不处理思维链
            if (jsonData.choices && jsonData.choices[0] && jsonData.choices[0].delta && jsonData.choices[0].delta.content) {
              accumulatedContent += jsonData.choices[0].delta.content;
            }
          } catch (e) {
            console.debug('跳过不完整的JSON数据:', line);
          }
        }
      }
    }
    
    // 设置最终消息
    if (accumulatedContent) {
      // 去除可能的引号和多余标点
      message.value = accumulatedContent.replace(/^["']|["']$/g, '').trim();
    }
  } catch (error) {
    console.error('获取鼓励消息失败:', error);
    // 出错时不显示错误，保持上一次的消息
  } finally {
    isLoading.value = false;
  }
};

// 设置定时更新
const setupInterval = () => {
  // 只有当编辑器有内容时才立即执行
  if (props.code && props.code.trim() !== '') {
    getEncouragement();
  }
  
  // 每分钟更新一次
  updateInterval.value = window.setInterval(() => {
    if (props.code && props.code.trim() !== '') {
      getEncouragement();
    }
  }, 60000); // 60秒 = 1分钟
};

// 自动滚动一次的函数
const autoScrollOnce = () => {
  if (!messageRef.value || scrollTimer.value) return;
  
  // 先暂停可能已经进行的动画
  if (messageRef.value.style.transform !== 'translateX(0px)') {
    messageRef.value.style.transition = 'none';
    messageRef.value.style.transform = 'translateX(0)';
    // 强制重绘
    void messageRef.value.offsetWidth;
  }
  
  const contentWidth = messageRef.value.scrollWidth;
  const containerWidth = messageRef.value.clientWidth;
  const scrollDistance = contentWidth - containerWidth;
  
  // 只有当需要滚动的距离大于10像素才滚动
  if (scrollDistance <= 10) return;
  
  // 自动滚动速度比悬停滚动稍快
  const duration = Math.min(Math.max(scrollDistance * 12, 1500), 5000);
  
  // 短暂延迟后开始滚动
  setTimeout(() => {
    if (messageRef.value) {
      messageRef.value.style.transition = `transform ${duration}ms cubic-bezier(0.3, 0.0, 0.3, 1.0)`;
      messageRef.value.style.transform = `translateX(-${scrollDistance}px)`;
      
      // 滚动结束后重置位置
      scrollTimer.value = window.setTimeout(() => {
        if (messageRef.value) {
          messageRef.value.style.transition = 'transform 500ms ease-in-out';
          messageRef.value.style.transform = 'translateX(0)';
          scrollTimer.value = null;
        }
      }, duration + 300);
    }
  }, 800); // 延迟稍长，让用户有时间注意到新消息
};

// 关闭tooltip
const closeTooltip = () => {
  showTooltip.value = false;
  if (tooltipTimer.value) {
    clearTimeout(tooltipTimer.value);
    tooltipTimer.value = null;
  }
};

onMounted(() => {
  setupInterval();
});

onBeforeUnmount(() => {
  if (updateInterval.value) {
    clearInterval(updateInterval.value);
    updateInterval.value = null;
  }
  
  if (scrollTimer.value) {
    clearTimeout(scrollTimer.value);
    scrollTimer.value = null;
  }
  
  if (tooltipTimer.value) {
    clearTimeout(tooltipTimer.value);
    tooltipTimer.value = null;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (message.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "message-wrapper",
          onMouseenter: startScroll,
          onMouseleave: stopScroll
        }, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "icon" }, "✨", -1)),
          _createElementVNode("div", {
            class: "message-container",
            onClick: toggleTooltip
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["message", {'scrollable': isOverflowing.value}]),
              ref_key: "messageRef",
              ref: messageRef
            }, [
              _createTextVNode(_toDisplayString(message.value) + " ", 1),
              (isOverflowing.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "⟺"))
                : _createCommentVNode("", true)
            ], 2),
            (isOverflowing.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : _createCommentVNode("", true)
          ]),
          ((isOverflowing.value && isHovering.value) || showTooltip.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(message.value), 1),
                (showTooltip.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("span", {
                        class: "tooltip-close",
                        onClick: _withModifiers(closeTooltip, ["stop"])
                      }, "关闭")
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 32))
      : (isLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "icon" }, "✨", -1),
            _createElementVNode("div", { class: "message typing-animation" }, null, -1)
          ])))
        : _createCommentVNode("", true)
  ]))
}
}

})