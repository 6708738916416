import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "templates-list"
}
const _hoisted_2 = { class: "templates-header" }
const _hoisted_3 = { class: "templates-footer" }
const _hoisted_4 = { class: "preview-content" }
const _hoisted_5 = { class: "preview-header" }
const _hoisted_6 = { class: "preview-info" }
const _hoisted_7 = { class: "preview-code" }

import { ref, reactive, onMounted, nextTick } from 'vue';
import { TemplateIcon, RefreshIcon } from 'tdesign-icons-vue-next';
import { MessagePlugin, DialogPlugin } from 'tdesign-vue-next';
import { useTemplatesStore } from '@/store/templates';
import { useUserStore } from '@/store/user';
import { useEditorStore } from '@/store/editor';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplatesManager',
  props: {
    code: {},
    language: {}
  },
  emits: ['update:code'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const userStore = useUserStore();
const templatesStore = useTemplatesStore();
const showDialog = ref(false);
const isAdding = ref(false);
const isSubmitting = ref(false);
const editorStore = useEditorStore();

const formData = reactive({
  name: '',
  description: ''
});

const previewVisible = ref(false);
const previewData = reactive({
  name: '',
  description: '',
  language: '',
  code: ''
});

onMounted(async () => {
  if (userStore.isLoggedIn) {
    await templatesStore.fetchTemplates();
  }
});

const handleClick = async () => {
  if (!userStore.isLoggedIn) {
    MessagePlugin.warning('请先登录后再使用模板功能');
    return;
  }
  showDialog.value = true;
  refreshTemplates();
};

const onSubmit = async ({ validateResult }: any) => {
  if (validateResult === true) {
    isSubmitting.value = true;
    
    const success = await templatesStore.addTemplate({
      name: formData.name,
      description: formData.description,
      code: props.code,
      language: props.language
    });
    
    if (success) {
      MessagePlugin.success('模板保存成功');
      formData.name = '';
      formData.description = '';
      isAdding.value = false;
    }
    
    isSubmitting.value = false;
  }
};

const useTemplate = (template: any) => {
  if (template.content?.code) {
    editorStore.setCode(template.content.code);
    emit('update:code', template.content.code);
    showDialog.value = false;
    MessagePlugin.success('模板应用成功');
  } else {
    MessagePlugin.error('模板数据异常');
  }
};

const confirmDelete = (id: string) => {
  const dialog = DialogPlugin.confirm({
    header: '删除确认',
    body: '确定要删除此模板吗？',
    confirmBtn: '删除',
    cancelBtn: '取消',
    theme: 'warning',
    onConfirm: async () => {
      const success = await templatesStore.removeTemplate(id);
      if (success) {
        MessagePlugin.success('模板已删除');
        dialog.destroy();
      }
    },
    onClose: () => {
      dialog.destroy();
    }
  });
};

const cancelAdd = () => {
  formData.name = '';
  formData.description = '';
  isAdding.value = false;
};

const refreshTemplates = async () => {
  if (!userStore.isLoggedIn) {
    MessagePlugin.warning('请先登录后再使用模板功能');
    return;
  }
  await templatesStore.fetchTemplates();
};

const getLanguageClass = () => {
  const languageMap: Record<string, string> = {
    'cpp': 'language-cpp',
    'c': 'language-c',
    'java': 'language-java',
    'python': 'language-python',
    'javascript': 'language-javascript',
    'typescript': 'language-typescript',
    'go': 'language-go',
    'rust': 'language-rust',
    'csharp': 'language-csharp',
    'php': 'language-php',
    'ruby': 'language-ruby',
    'swift': 'language-swift',
    'kotlin': 'language-kotlin',
    'scala': 'language-scala',
    'sql': 'language-sql'
  };
  return languageMap[previewData.language] || 'language-plaintext';
};

const previewTemplate = (template: any) => {
  previewData.name = template.name;
  previewData.description = template.description;
  previewData.language = template.content?.language;
  previewData.code = template.content?.code;
  previewVisible.value = true;
  
  nextTick(() => {
    const codeElement = document.querySelector('.preview-code code');
    if (codeElement) {
      hljs.highlightElement(codeElement);
    }
  });
};

return (_ctx: any,_cache: any) => {
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_tooltip = _resolveComponent("t-tooltip")!
  const _component_t_input = _resolveComponent("t-input")!
  const _component_t_form_item = _resolveComponent("t-form-item")!
  const _component_t_textarea = _resolveComponent("t-textarea")!
  const _component_t_space = _resolveComponent("t-space")!
  const _component_t_form = _resolveComponent("t-form")!
  const _component_t_tag = _resolveComponent("t-tag")!
  const _component_t_list_item_meta = _resolveComponent("t-list-item-meta")!
  const _component_t_list_item = _resolveComponent("t-list-item")!
  const _component_t_list = _resolveComponent("t-list")!
  const _component_t_empty = _resolveComponent("t-empty")!
  const _component_t_loading = _resolveComponent("t-loading")!
  const _component_t_dialog = _resolveComponent("t-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_t_tooltip, { content: "保存为代码模板" }, {
      default: _withCtx(() => [
        _createVNode(_component_t_button, {
          variant: "outline",
          theme: "default",
          onClick: handleClick
        }, {
          icon: _withCtx(() => [
            _createVNode(_unref(TemplateIcon))
          ]),
          default: _withCtx(() => [
            _cache[5] || (_cache[5] = _createElementVNode("span", null, "模板", -1))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_t_dialog, {
      visible: showDialog.value,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((showDialog).value = $event)),
      header: isAdding.value ? '保存模板' : '代码模板',
      width: 600,
      footer: false,
      closeOnOverlayClick: true,
      showOverlay: true
    }, {
      default: _withCtx(() => [
        (isAdding.value)
          ? (_openBlock(), _createBlock(_component_t_form, {
              key: 0,
              ref: "form",
              data: formData,
              onSubmit: onSubmit
            }, {
              default: _withCtx(() => [
                _createVNode(_component_t_form_item, {
                  label: "模板名称",
                  name: "name",
                  rules: [{ required: true }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_t_input, {
                      modelValue: formData.name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.name) = $event)),
                      placeholder: "请输入模板名称"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_t_form_item, {
                  label: "描述",
                  name: "description"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_t_textarea, {
                      modelValue: formData.description,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.description) = $event)),
                      placeholder: "请输入模板描述（可选）",
                      autosize: { minRows: 2, maxRows: 4 }
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_t_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_t_space, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_t_button, {
                          theme: "primary",
                          type: "submit",
                          loading: isSubmitting.value
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode("保存")
                          ])),
                          _: 1
                        }, 8, ["loading"]),
                        _createVNode(_component_t_button, {
                          theme: "default",
                          onClick: cancelAdd
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("取消")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "templates-title" }, "我的模板", -1)),
                _createVNode(_component_t_button, {
                  theme: "default",
                  variant: "text",
                  loading: _unref(templatesStore).isLoading,
                  onClick: refreshTemplates,
                  disabled: _unref(templatesStore).isLoading
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_unref(RefreshIcon))
                  ]),
                  default: _withCtx(() => [
                    _cache[8] || (_cache[8] = _createTextVNode(" 刷新 "))
                  ]),
                  _: 1
                }, 8, ["loading", "disabled"])
              ]),
              _createVNode(_component_t_loading, {
                loading: _unref(templatesStore).isLoading
              }, {
                default: _withCtx(() => [
                  (_unref(templatesStore).templates.length)
                    ? (_openBlock(), _createBlock(_component_t_list, { key: 0 }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(templatesStore).templates, (template) => {
                            return (_openBlock(), _createBlock(_component_t_list_item, {
                              key: template.id
                            }, {
                              action: _withCtx(() => [
                                _createVNode(_component_t_space, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_t_button, {
                                      theme: "default",
                                      variant: "text",
                                      onClick: ($event: any) => (previewTemplate(template))
                                    }, {
                                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                                        _createTextVNode(" 预览 ")
                                      ])),
                                      _: 2
                                    }, 1032, ["onClick"]),
                                    _createVNode(_component_t_button, {
                                      theme: "primary",
                                      variant: "text",
                                      onClick: ($event: any) => (useTemplate(template))
                                    }, {
                                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                                        _createTextVNode(" 使用 ")
                                      ])),
                                      _: 2
                                    }, 1032, ["onClick"]),
                                    _createVNode(_component_t_button, {
                                      theme: "danger",
                                      variant: "text",
                                      onClick: ($event: any) => (confirmDelete(template.id))
                                    }, {
                                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                                        _createTextVNode(" 删除 ")
                                      ])),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_t_list_item_meta, {
                                  title: template.name,
                                  description: template.description || '暂无描述'
                                }, {
                                  avatar: _withCtx(() => [
                                    _createVNode(_component_t_tag, {
                                      theme: "primary",
                                      variant: "light"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(template.content?.language || '未知'), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["title", "description"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_t_empty, {
                        key: 1,
                        description: "暂无保存的模板"
                      }))
                ]),
                _: 1
              }, 8, ["loading"]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_t_button, {
                  theme: "primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (isAdding.value = true))
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" 保存当前代码为模板 ")
                  ])),
                  _: 1
                })
              ])
            ]))
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_component_t_dialog, {
      visible: previewVisible.value,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((previewVisible).value = $event)),
      header: "模板预览",
      width: 800,
      footer: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h3", null, _toDisplayString(previewData.name), 1),
              _createElementVNode("p", null, _toDisplayString(previewData.description || '暂无描述'), 1)
            ]),
            _createVNode(_component_t_tag, {
              theme: "primary",
              variant: "light"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(previewData.language || '未知'), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("pre", null, [
              _createElementVNode("code", {
                class: _normalizeClass(getLanguageClass())
              }, _toDisplayString(previewData.code), 3)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})