<template>
  <t-drawer
    :visible="visible"
    :size="'50%'"
    header="AI助手"
    @close="handleClose"
    :closeOnOverlayClick="true"
    :showOverlay="true"
    :size-draggable="true"
  >
    <div class="ai-content">
      <div id="chatLog" class="chat-log">
        <template v-if="chatHistory.length === 0">
          <div class="initial-state">
            <t-empty
              description='在下方输入问题开始与AI对话'
              icon="chat"
            />
          </div>
        </template>
        
        <div v-for="(message, index) in chatHistory" :key="index" 
             class="message-container" 
             :class="message.sender === 'user' ? 'user-message' : 'ai-message'">
          <div class="message-avatar">
            {{ message.sender === 'user' ? '你' : 'AI' }}
          </div>
          <div class="message-content">
            <template v-if="message.sender === 'ai' && message.think">
              <div class="think-toggle" @click="toggleThink(index)">
                💭 {{ thinkVisible[index] ? '隐藏思维过程' : '显示思维过程' }}
              </div>
              <div class="think-block" :style="{ display: thinkVisible[index] ? 'block' : 'none' }">
                {{ message.think }}
              </div>
            </template>
            <div class="markdown-content" v-if="message.sender === 'user'" v-text="message.content"></div>
            <div class="markdown-content" v-else v-html="message.renderedContent"></div>
          </div>
        </div>
        
        <div v-if="isLoading" class="message-container ai-message">
          <div class="message-avatar">AI</div>
          <div class="message-content">
            <div class="markdown-content typing-animation"></div>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="input-container">
        <div class="input-actions">
          <t-button 
            theme="default" 
            size="small" 
            @click="insertCurrentCode"
            :class="{ 'code-button-active': includeCode }"
          >
            <template #icon><t-icon name="code" /></template>
            {{ includeCode ? '已选择代码' : '插入当前代码' }}
          </t-button>
          <t-button theme="default" size="small" @click="clearHistory">
            <template #icon><t-icon name="clear" /></template>
            清空历史
          </t-button>
        </div>
        <div class="input-group">
          <t-textarea
            v-model="userInput"
            class="form-control"
            placeholder="请输入您的问题..."
            :autosize="{ minRows: 1, maxRows: 5 }"
            @keydown="handleKeyDown"
            @focus="inputFocused = true"
            @blur="inputFocused = false"
          ></t-textarea>
          <t-button 
            theme="primary" 
            @click="sendMessage" 
            :loading="isLoading"
            :disabled="isLoading || !userInput.trim()"
            class="send-button"
          >
            <template #icon><t-icon name="send" /></template>
          </t-button>
        </div>
        <div class="input-tips">
          <span>DeepSeek R1 模型</span>
        </div>
      </div>
    </template>
  </t-drawer>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits, onBeforeUnmount, computed, reactive } from 'vue';
import { MessagePlugin, DialogPlugin } from 'tdesign-vue-next';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { useEditorStore } from '@/store/editor';

// 配置 marked
marked.setOptions({
  gfm: true,
  breaks: true,
  pedantic: false
});

interface ChatMessage {
  sender: 'user' | 'ai';
  content: string;
  renderedContent?: string;
  think?: string | null;
}

const props = defineProps({
  visible: Boolean,
  code: String,
});

const emit = defineEmits(['update:visible']);
const isLoading = ref(false);
const userInput = ref('');
const chatHistory = ref<ChatMessage[]>([]);
const streamController = ref<AbortController | null>(null);
const thinkVisible = reactive<Record<number, boolean>>({});
const inputFocused = ref(false);
const includeCode = ref(false);

const editorStore = useEditorStore();

// 从localStorage加载历史记录
const loadHistory = () => {
  try {
    const savedHistory = localStorage.getItem('aiChatHistory');
    if (savedHistory) {
      const history = JSON.parse(savedHistory) as ChatMessage[];
      // 为AI消息添加渲染后的内容
      history.forEach(msg => {
        if (msg.sender === 'ai') {
          msg.renderedContent = DOMPurify.sanitize(marked.parse(msg.content));
        }
      });
      chatHistory.value = history;
      // 初始化思维链显示状态
      history.forEach((_, index) => {
        thinkVisible[index] = true;
      });
    }
  } catch (e) {
    console.error('加载聊天历史失败:', e);
  }
};

// 保存历史记录到localStorage
const saveHistory = () => {
  try {
    localStorage.setItem('aiChatHistory', JSON.stringify(chatHistory.value));
  } catch (e) {
    console.error('保存聊天历史失败:', e);
  }
};

// 切换思维链显示状态
const toggleThink = (index: number) => {
  thinkVisible[index] = !thinkVisible[index];
};

const handleClose = () => {
  emit('update:visible', false);
};

const handleKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    if (e.shiftKey) {
      // Shift + Enter 换行，保持默认行为
      return;
    } else {
      // 仅 Enter 发送消息
      e.preventDefault();
      sendMessage();
    }
  }
};

const scrollToBottom = () => {
  setTimeout(() => {
    const chatLogElement = document.getElementById('chatLog');
    if (chatLogElement) {
      chatLogElement.scrollTop = chatLogElement.scrollHeight;
    }
  }, 50);
};

const sendMessage = async () => {
  const text = userInput.value.trim();
  if ((!text && !includeCode.value) || isLoading.value) return;

  // 准备发送内容
  let messageContent = text;
  
  // 如果需要包含代码，添加代码块
  if (includeCode.value) {
    const currentCode = props.code || editorStore.code || '';
    const codeBlock = `\`\`\`\n${currentCode}\n\`\`\`\n`;
    
    if (messageContent) {
      // 将代码放在用户输入的上方，而不是下方
      messageContent = `${codeBlock}\n\n${messageContent}`;
    } else {
      messageContent = codeBlock;
    }
    
    // 重置状态
    includeCode.value = false;
  }

  // 添加用户消息到历史
  chatHistory.value.push({
    sender: 'user',
    content: messageContent
  });
  
  userInput.value = '';
  isLoading.value = true;
  scrollToBottom();
  
  if (streamController.value) {
    streamController.value.abort();
  }
  
  streamController.value = new AbortController();
  
  try {
    // 准备请求数据
    const requestData = {
      prompt: messageContent,
      history: chatHistory.value.slice(0, -1) // 不包含最新的用户消息
    };
    
    const response = await fetch('https://aiapiv3.emoera.top/stream', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
      signal: streamController.value.signal
    });

    if (!response.ok) {
      throw new Error('网络请求失败');
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('无法读取响应流');
    }

    // 创建AI回复的消息对象
    let accumulatedContent = "";
    let reasoningContent = "";
    
    // 添加一个空的AI回复到历史
    chatHistory.value.push({
      sender: 'ai',
      content: '',
      renderedContent: '',
      think: null
    });
    
    const aiMessageIndex = chatHistory.value.length - 1;
    thinkVisible[aiMessageIndex] = true;
    
    const decoder = new TextDecoder();
    
    for (;;) {
      const { done, value } = await reader.read();
      if (done) break;
      
      const text = decoder.decode(value);
      const lines = text.split('\n');
      
      for (const line of lines) {
        if (!line.trim()) continue;
        
        if (line.startsWith('data: ')) {
          const data = line.slice(6);
          if (data === '[DONE]') continue;
          
          try {
            const jsonData = JSON.parse(data);
            
            // 处理思维链内容
            if (jsonData.choices[0].delta.reasoning_content) {
              const content = jsonData.choices[0].delta.reasoning_content;
              const cleanContent = content.replace(/<\/?think>/gi, '');
              if (cleanContent.trim()) {
                reasoningContent += cleanContent;
                chatHistory.value[aiMessageIndex].think = reasoningContent;
              }
            }
            
            // 处理主要内容
            if (jsonData.choices[0].delta.content) {
              const content = jsonData.choices[0].delta.content;
              accumulatedContent += content;
              chatHistory.value[aiMessageIndex].content = accumulatedContent;
              chatHistory.value[aiMessageIndex].renderedContent = DOMPurify.sanitize(marked.parse(accumulatedContent));
              scrollToBottom();
            }
          } catch (e) {
            if (e instanceof SyntaxError) {
              console.debug('跳过不完整的JSON数据:', line);
              continue;
            }
            throw e;
          }
        }
      }
    }
    
    // 保存历史记录
    saveHistory();
  } catch (err: any) {
    if (err.name === 'AbortError') {
      console.log('请求被中止');
      return;
    }
    const errorMessage = err.message || '请求失败，请稍后重试';
    MessagePlugin.error(errorMessage);
    
    // 如果已经添加了AI消息但失败了，更新为错误消息
    if (chatHistory.value.length > 0 && chatHistory.value[chatHistory.value.length - 1].sender === 'ai') {
      chatHistory.value[chatHistory.value.length - 1].content = `请求出错: ${errorMessage}`;
      chatHistory.value[chatHistory.value.length - 1].renderedContent = DOMPurify.sanitize(marked.parse(`请求出错: ${errorMessage}`));
    } else {
      // 否则添加一个新的错误消息
      chatHistory.value.push({
        sender: 'ai',
        content: `请求出错: ${errorMessage}`,
        renderedContent: DOMPurify.sanitize(marked.parse(`请求出错: ${errorMessage}`)),
        think: null
      });
    }
    saveHistory();
  } finally {
    isLoading.value = false;
    streamController.value = null;
  }
};

// 插入当前代码到输入框
const insertCurrentCode = () => {
  const currentCode = props.code || editorStore.code || '';
  if (!currentCode.trim()) {
    MessagePlugin.warning('当前没有可插入的代码');
    return;
  }
  
  // 切换状态而不是直接修改输入框
  includeCode.value = !includeCode.value;
};

// 清除历史记录
const clearHistory = () => {
  chatHistory.value = [];
  saveHistory();
  MessagePlugin.success('聊天历史已清空');
};

// 组件挂载时加载历史记录
loadHistory();

onBeforeUnmount(() => {
  if (streamController.value) {
    streamController.value.abort();
  }
});
</script>

<style scoped>
.ai-content {
  padding: 16px;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
}

.chat-log {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  scroll-behavior: smooth;
}

.message-container {
  display: flex;
  margin-bottom: 1.5rem;
  gap: 1rem;
}

.message-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  background: var(--td-brand-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
}

.user-message .message-avatar {
  background: var(--td-brand-color-8);
}

.message-content {
  flex: 1;
  max-width: calc(100% - 3.5rem);
}

.user-message .message-content {
  background: var(--td-bg-color-container-hover);
  border-radius: 0.5rem;
  padding: 1rem;
  white-space: pre-wrap;
}

.ai-message .message-content {
  background: var(--td-bg-color-container);
  border: 1px solid var(--td-component-stroke);
  border-radius: 0.5rem;
  padding: 1rem;
}

.think-block {
  font-size: 0.875rem;
  color: var(--td-text-color-secondary);
  background: var(--td-bg-color-page);
  border-left: 3px solid var(--td-text-color-secondary);
  margin: 0.5rem 0;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  white-space: pre-wrap;
}

.think-toggle {
  font-size: 0.875rem;
  color: var(--td-text-color-secondary);
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
}

.think-toggle:hover {
  color: var(--td-brand-color);
}

.markdown-content {
  line-height: 1.6;
  font-size: 0.9375rem;
}

.input-container {
  padding: 16px;
  border-top: 1px solid var(--td-component-stroke);
  width: 100%;
  background-color: var(--td-bg-color-container);
}

.input-actions {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  justify-content: flex-start;
}

.input-group {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  position: relative;
  border-radius: 8px;
  transition: all 0.3s;
  border: 1px solid var(--td-component-stroke);
  background-color: var(--td-bg-color-container);
  padding: 4px;
}

.input-group:focus-within {
  border-color: var(--td-brand-color);
  box-shadow: 0 0 0 2px rgba(var(--td-brand-color-rgb), 0.1);
}

.form-control {
  flex: 1;
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  padding: 8px 12px !important;
}

.initial-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding: 20px;
}

:deep(.t-empty) {
  padding: 32px;
}

:deep(.t-empty__description) {
  color: var(--td-text-color-secondary);
  font-size: 14px;
  margin-top: 16px;
}

:deep(.t-empty__image) {
  display: flex;
}

/* 添加打字机效果 */
@keyframes typing {
  0% { content: "思考中"; }
  25% { content: "思考中."; }
  50% { content: "思考中.."; }
  75% { content: "思考中..."; }
}

.typing-animation::after {
  content: "思考中";
  animation: typing 1.5s infinite;
  color: var(--td-text-color-secondary);
  font-size: 0.9375rem;
}

/* 导入markdown样式 */
:deep(.markdown-content) {
  line-height: 1.6;
}

:deep(.markdown-content p) {
  margin-bottom: 1rem;
}

:deep(.markdown-content h1),
:deep(.markdown-content h2),
:deep(.markdown-content h3) {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
}

:deep(.markdown-content code) {
  background: var(--td-bg-color-page);
  padding: 0.2em 0.4em;
  border-radius: 0.25rem;
  font-size: 0.875em;
  font-family: 'Fira Code', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
}

:deep(.markdown-content pre) {
  background: var(--td-bg-color-page);
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-x: auto;
  margin: 1rem 0;
}

:deep(.markdown-content pre code) {
  background: none;
  padding: 0;
  font-size: 0.875rem;
}

:deep(.t-textarea__inner) {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 8px 0 !important;
}

.send-button {
  align-self: flex-end;
  margin-bottom: 4px;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-tips {
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
  font-size: 12px;
  color: var(--td-text-color-secondary);
}

/* 添加激活状态的样式 */
.code-button-active {
  background-color: var(--td-brand-color-light) !important;
  color: var(--td-brand-color) !important;
  border-color: var(--td-brand-color-light) !important;
}
</style>