<template>
  <t-tooltip content="保存为代码模板">
    <t-button
      variant="outline"
      theme="default"
      @click="handleClick"
    >
      <template #icon>
        <template-icon />
      </template>
      <span>模板</span>
    </t-button>
  </t-tooltip>

  <t-dialog
    v-model:visible="showDialog"
    :header="isAdding ? '保存模板' : '代码模板'"
    :width="600"
    :footer="false"
    :closeOnOverlayClick="true"
    :showOverlay="true"
  >
    <template v-if="isAdding">
      <t-form ref="form" :data="formData" @submit="onSubmit">
        <t-form-item label="模板名称" name="name" :rules="[{ required: true }]">
          <t-input v-model="formData.name" placeholder="请输入模板名称" />
        </t-form-item>
        <t-form-item label="描述" name="description">
          <t-textarea
            v-model="formData.description"
            placeholder="请输入模板描述（可选）"
            :autosize="{ minRows: 2, maxRows: 4 }"
          />
        </t-form-item>
        <t-form-item>
          <t-space>
            <t-button theme="primary" type="submit" :loading="isSubmitting">保存</t-button>
            <t-button theme="default" @click="cancelAdd">取消</t-button>
          </t-space>
        </t-form-item>
      </t-form>
    </template>

    <template v-else>
      <div class="templates-list">
        <div class="templates-header">
          <span class="templates-title">我的模板</span>
          <t-button
            theme="default"
            variant="text"
            :loading="templatesStore.isLoading"
            @click="refreshTemplates"
            :disabled="templatesStore.isLoading"
          >
            <template #icon>
              <refresh-icon />
            </template>
            刷新
          </t-button>
        </div>

        <t-loading :loading="templatesStore.isLoading">
          <template v-if="templatesStore.templates.length">
            <t-list>
              <t-list-item
                v-for="template in templatesStore.templates"
                :key="template.id"
              >
                <t-list-item-meta
                  :title="template.name"
                  :description="template.description || '暂无描述'"
                >
                  <template #avatar>
                    <t-tag theme="primary" variant="light">
                      {{ template.content?.language || '未知' }}
                    </t-tag>
                  </template>
                </t-list-item-meta>
                <template #action>
                  <t-space>
                    <t-button
                      theme="default"
                      variant="text"
                      @click="previewTemplate(template)"
                    >
                      预览
                    </t-button>
                    <t-button
                      theme="primary"
                      variant="text"
                      @click="useTemplate(template)"
                    >
                      使用
                    </t-button>
                    <t-button
                      theme="danger"
                      variant="text"
                      @click="confirmDelete(template.id)"
                    >
                      删除
                    </t-button>
                  </t-space>
                </template>
              </t-list-item>
            </t-list>
          </template>
          <t-empty v-else description="暂无保存的模板" />
        </t-loading>
        
        <div class="templates-footer">
          <t-button theme="primary" @click="isAdding = true">
            保存当前代码为模板
          </t-button>
        </div>
      </div>
    </template>
  </t-dialog>

  <t-dialog
    v-model:visible="previewVisible"
    header="模板预览"
    :width="800"
    :footer="false"
  >
    <div class="preview-content">
      <div class="preview-header">
        <div class="preview-info">
          <h3>{{ previewData.name }}</h3>
          <p>{{ previewData.description || '暂无描述' }}</p>
        </div>
        <t-tag theme="primary" variant="light">
          {{ previewData.language || '未知' }}
        </t-tag>
      </div>
      <div class="preview-code">
        <pre><code :class="getLanguageClass()">{{ previewData.code }}</code></pre>
      </div>
    </div>
  </t-dialog>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, nextTick } from 'vue';
import { TemplateIcon, RefreshIcon } from 'tdesign-icons-vue-next';
import { MessagePlugin, DialogPlugin } from 'tdesign-vue-next';
import { useTemplatesStore } from '@/store/templates';
import { useUserStore } from '@/store/user';
import { useEditorStore } from '@/store/editor';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';

const props = defineProps<{
  code: string;
  language: string;
}>();

const emit = defineEmits(['update:code']);

const userStore = useUserStore();
const templatesStore = useTemplatesStore();
const showDialog = ref(false);
const isAdding = ref(false);
const isSubmitting = ref(false);
const editorStore = useEditorStore();

const formData = reactive({
  name: '',
  description: ''
});

const previewVisible = ref(false);
const previewData = reactive({
  name: '',
  description: '',
  language: '',
  code: ''
});

onMounted(async () => {
  if (userStore.isLoggedIn) {
    await templatesStore.fetchTemplates();
  }
});

const handleClick = async () => {
  if (!userStore.isLoggedIn) {
    MessagePlugin.warning('请先登录后再使用模板功能');
    return;
  }
  showDialog.value = true;
  refreshTemplates();
};

const onSubmit = async ({ validateResult }: any) => {
  if (validateResult === true) {
    isSubmitting.value = true;
    
    const success = await templatesStore.addTemplate({
      name: formData.name,
      description: formData.description,
      code: props.code,
      language: props.language
    });
    
    if (success) {
      MessagePlugin.success('模板保存成功');
      formData.name = '';
      formData.description = '';
      isAdding.value = false;
    }
    
    isSubmitting.value = false;
  }
};

const useTemplate = (template: any) => {
  if (template.content?.code) {
    editorStore.setCode(template.content.code);
    emit('update:code', template.content.code);
    showDialog.value = false;
    MessagePlugin.success('模板应用成功');
  } else {
    MessagePlugin.error('模板数据异常');
  }
};

const confirmDelete = (id: string) => {
  const dialog = DialogPlugin.confirm({
    header: '删除确认',
    body: '确定要删除此模板吗？',
    confirmBtn: '删除',
    cancelBtn: '取消',
    theme: 'warning',
    onConfirm: async () => {
      const success = await templatesStore.removeTemplate(id);
      if (success) {
        MessagePlugin.success('模板已删除');
        dialog.destroy();
      }
    },
    onClose: () => {
      dialog.destroy();
    }
  });
};

const cancelAdd = () => {
  formData.name = '';
  formData.description = '';
  isAdding.value = false;
};

const refreshTemplates = async () => {
  if (!userStore.isLoggedIn) {
    MessagePlugin.warning('请先登录后再使用模板功能');
    return;
  }
  await templatesStore.fetchTemplates();
};

const getLanguageClass = () => {
  const languageMap: Record<string, string> = {
    'cpp': 'language-cpp',
    'c': 'language-c',
    'java': 'language-java',
    'python': 'language-python',
    'javascript': 'language-javascript',
    'typescript': 'language-typescript',
    'go': 'language-go',
    'rust': 'language-rust',
    'csharp': 'language-csharp',
    'php': 'language-php',
    'ruby': 'language-ruby',
    'swift': 'language-swift',
    'kotlin': 'language-kotlin',
    'scala': 'language-scala',
    'sql': 'language-sql'
  };
  return languageMap[previewData.language] || 'language-plaintext';
};

const previewTemplate = (template: any) => {
  previewData.name = template.name;
  previewData.description = template.description;
  previewData.language = template.content?.language;
  previewData.code = template.content?.code;
  previewVisible.value = true;
  
  nextTick(() => {
    const codeElement = document.querySelector('.preview-code code');
    if (codeElement) {
      hljs.highlightElement(codeElement);
    }
  });
};
</script>

<style scoped>
.templates-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 16px;
  border-bottom: 1px solid var(--td-component-stroke);
}

.templates-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--td-text-color-primary);
}

.templates-list {
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.templates-footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--td-component-stroke);
  text-align: center;
}

:deep(.t-list-item) {
  padding: 16px;
}

:deep(.t-list-item:hover) {
  background-color: var(--td-bg-color-container-hover);
}

:deep(.t-list-item__meta) {
  align-items: center;
}

.preview-content {
  padding: 16px;
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.preview-info h3 {
  margin: 0 0 8px;
  font-size: 16px;
  color: var(--td-text-color-primary);
}

.preview-info p {
  margin: 0;
  font-size: 14px;
  color: var(--td-text-color-secondary);
}

.preview-code {
  background-color: var(--td-bg-color-container);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.preview-code pre {
  margin: 0;
  padding: 16px;
  max-height: 400px;
  overflow: auto;
  background-color: transparent;
}

.preview-code code {
  font-family: 'Fira Code', monospace;
  font-size: 14px;
  line-height: 1.6;
  background-color: transparent !important;
  padding: 0 !important;
}

:deep(.hljs) {
  background-color: transparent !important;
  padding: 0 !important;
}

.preview-code pre::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.preview-code pre::-webkit-scrollbar-track {
  background: var(--td-bg-color-component);
  border-radius: 3px;
}

.preview-code pre::-webkit-scrollbar-thumb {
  background: var(--td-component-stroke);
  border-radius: 3px;
}

.preview-code pre::-webkit-scrollbar-thumb:hover {
  background: var(--td-gray-color-6);
}
</style> 