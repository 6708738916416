import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "voice-chat-container" }
const _hoisted_2 = { class: "voice-chat-content" }
const _hoisted_3 = { class: "setup-panel" }
const _hoisted_4 = { class: "microphone-test" }
const _hoisted_5 = { class: "level-bar-container" }
const _hoisted_6 = { class: "level-value" }
const _hoisted_7 = {
  key: 0,
  class: "test-result"
}
const _hoisted_8 = {
  key: 0,
  style: {"display":"none"}
}

import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import { MessagePlugin } from 'tdesign-vue-next';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { MicrophoneIcon, CallOffIcon } from 'tdesign-icons-vue-next';

// 组件属性

export default /*@__PURE__*/_defineComponent({
  __name: 'VoiceChat',
  props: {
  visible: {
    type: Boolean,
    default: false
  }
},
  emits: ['update:visible', 'connected', 'disconnected', 'mute-change', 'user-count-change'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

// 组件事件
const emit = __emit;

// 使用计算属性处理 visible
const dialogVisible = computed({
  get: () => props.visible && !isConnected.value,
  set: (val) => emit('update:visible', val)
});

// 状态变量
const appId = ref('68bed0a062e34ca888d3555355375ed8'); // 固定的 App ID
const token = ref('');
const channelName = ref(''); // 默认频道名
const isConnected = ref(false);
const isJoining = ref(false);
const isMuted = ref(false);
const participants = ref<any[]>([]);
const rtcClient = ref<any>(null);
const localAudioTrack = ref<any>(null);
const localUid = ref<number>(0);
const isTesting = ref(false);
const microphoneLevel = ref<number | null>(null);
const testResult = ref('');
const testInterval = ref<number | null>(null);
const isAutoClosing = ref(false);

// 监听 visible 变化
const updateVisible = (val: boolean) => {
  emit('update:visible', val);
  if (!val && isConnected.value && !isAutoClosing.value) {
    leaveChannel();
  }
};

// 添加 watch 监听
watch(() => props.visible, (newVal) => {
  if (!newVal && isConnected.value && !isAutoClosing.value) {
    leaveChannel();
  }
});

// 加入频道
const joinChannel = async () => {
  if (!channelName.value) {
    MessagePlugin.error('请输入频道名称');
    return;
  }

  try {
    isJoining.value = true;

    // 初始化 RTC 客户端
    rtcClient.value = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
    
    // 生成随机 UID
    localUid.value = Math.floor(Math.random() * 1000000);
    
    // 注册事件监听
    setupEventListeners();
    
    // 加入频道
    await rtcClient.value.join(
      appId.value,
      channelName.value,
      token.value || null,
      localUid.value
    );
    
    // 创建并发布本地音频轨道
    localAudioTrack.value = await AgoraRTC.createMicrophoneAudioTrack();
    await rtcClient.value.publish([localAudioTrack.value]);
    
    // 添加自己到参与者列表
    const localUserInfo = {
      uid: localUid.value,
      name: `用户 ${localUid.value}`,
      isMuted: false,
      isSpeaking: false,
      isLocal: true,
      online: true
    };
    
    participants.value.push(localUserInfo);
    
    isConnected.value = true;
    isAutoClosing.value = true;
    emit('update:visible', false);
    setTimeout(() => {
      isAutoClosing.value = false;
    }, 100);
    
    MessagePlugin.success('成功加入频道');
    
    // 发出连接成功事件
    emit('connected');
    
    // 发出用户数量变更事件
    emit('user-count-change', 1);
    
    // 启动状态同步
    startStatusSync();
  } catch (error: any) {
    console.error('加入频道失败:', error);
    MessagePlugin.error(`加入频道失败: ${error.message || '未知错误'}`);
  } finally {
    isJoining.value = false;
  }
};

// 设置事件监听
const setupEventListeners = () => {
  if (!rtcClient.value) return;
  
  // 监听频道连接状态变化
  rtcClient.value.on('connection-state-change', (curState: string, prevState: string) => {
    console.log(`频道连接状态从 ${prevState} 变为 ${curState}`);
    
    // 如果连接状态变为CONNECTED，表示完全连接到频道
    if (curState === 'CONNECTED') {
      console.log('已完全连接到频道，可以开始音频通话');
    }
    
    // 如果连接状态变为DISCONNECTED或FAILED，可能需要重新连接
    if (curState === 'DISCONNECTED' || curState === 'FAILED') {
      console.warn(`频道连接状态变为 ${curState}，可能需要重新连接`);
      
      // 标记所有远程用户为离线
      participants.value.forEach(participant => {
        if (!participant.isLocal) {
          participant.online = false;
        }
      });
    }
  });
  
  // 用户加入事件
  rtcClient.value.on('user-joined', async (user: any) => {
    console.log(`用户 ${user.uid} 加入了频道`);
    
    try {
      // 检查用户是否已经在参与者列表中
      const existingUserIndex = participants.value.findIndex(p => p.uid === user.uid);
      if (existingUserIndex !== -1) {
        console.log(`用户 ${user.uid} 已经在参与者列表中，更新状态`);
        // 更新用户状态为在线
        participants.value[existingUserIndex].online = true;
        return;
      }
      
      // 延迟添加用户，确保用户完全加入频道
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // 再次检查用户是否已经在参与者列表中（可能在延迟期间被添加）
      const checkAgain = participants.value.findIndex(p => p.uid === user.uid);
      if (checkAgain !== -1) {
        console.log(`延迟期间用户 ${user.uid} 已被添加到参与者列表`);
        return;
      }
      
      // 添加新用户到参与者列表
      const newUser = {
        uid: user.uid,
        name: `用户 ${user.uid}`,
        isMuted: true, // 初始状态为静音，直到收到音频
        isSpeaking: false,
        isLocal: false,
        online: true
      };
      
      participants.value.push(newUser);
      MessagePlugin.info(`用户 ${user.uid} 加入了频道`);
    } catch (error: any) {
      console.error(`处理用户 ${user.uid} 加入事件时出错:`, error);
    }
  });
  
  // 用户离开事件
  rtcClient.value.on('user-left', (user: any) => {
    console.log(`用户 ${user.uid} 离开了频道`);
    
    try {
      // 从参与者列表中移除用户
      const index = participants.value.findIndex(p => p.uid === user.uid);
      if (index !== -1) {
        // 标记用户为离线，但不立即从列表中移除
        const updatedUser = { ...participants.value[index], online: false };
        participants.value.splice(index, 1, updatedUser);
        
        // 延迟从列表中移除用户，避免与其他事件冲突
        setTimeout(() => {
          // 再次检查用户是否仍在参与者列表中
          const currentIndex = participants.value.findIndex(p => p.uid === user.uid);
          if (currentIndex !== -1) {
            // 再次检查用户是否真的离开了频道
            if (!isUserInChannel(user.uid)) {
              participants.value.splice(currentIndex, 1);
              console.log(`已从参与者列表中移除用户 ${user.uid}`);
            } else {
              console.log(`用户 ${user.uid} 仍在频道中，取消移除操作`);
              // 恢复用户在线状态
              const updatedUser = { ...participants.value[currentIndex], online: true };
              participants.value.splice(currentIndex, 1, updatedUser);
            }
          }
        }, 5000); // 延长到 5 秒，确保所有事件都已处理完毕
        
        MessagePlugin.info(`用户 ${user.uid} 离开了频道`);
      } else {
        console.warn(`用户 ${user.uid} 不在参与者列表中，无法移除`);
      }
    } catch (error: any) {
      console.error(`处理用户 ${user.uid} 离开事件时出错:`, error);
    }
  });
  
  // 用户发布轨道事件
  rtcClient.value.on('user-published', async (user: any, mediaType: string) => {
    if (mediaType === 'audio') {
      try {
        console.log(`用户 ${user.uid} 发布了音频轨道`);
        
        // 先将用户添加到参与者列表（如果不存在）
        const existingUserIndex = participants.value.findIndex(p => p.uid === user.uid);
        if (existingUserIndex === -1) {
          console.log(`用户 ${user.uid} 不在参与者列表中，添加用户`);
          participants.value.push({
            uid: user.uid,
            name: `用户 ${user.uid}`,
            isMuted: true,
            isSpeaking: false,
            isLocal: false,
            online: true
          });
        } else {
          // 确保用户状态为在线
          if (participants.value[existingUserIndex].online === false) {
            console.log(`用户 ${user.uid} 在参与者列表中但状态为离线，更新为在线`);
            const updatedUser = { ...participants.value[existingUserIndex], online: true };
            participants.value.splice(existingUserIndex, 1, updatedUser);
          }
        }
        
        // 添加更长的初始延迟，确保用户已完全加入频道
        console.log(`等待 5 秒，确保用户 ${user.uid} 完全加入频道...`);
        await new Promise(resolve => setTimeout(resolve, 5000));
        
        // 预检查：确保用户在远程用户列表中且有音频轨道
        const remoteUsers = rtcClient.value.remoteUsers || [];
        const remoteUser = remoteUsers.find((remote: any) => remote.uid === user.uid);
        
        if (!remoteUser) {
          console.warn(`预检查：用户 ${user.uid} 不在远程用户列表中，无法订阅音频`);
          return;
        }
        
        if (!remoteUser.hasAudio) {
          console.warn(`预检查：用户 ${user.uid} 没有音频轨道，等待音频轨道发布`);
          // 不立即返回，继续尝试，因为hasAudio状态可能不准确
        }
        
        // 使用最新的用户对象
        user = remoteUser;
        
        // 检查用户是否仍在频道中
        const userInChannel = isUserInChannel(user.uid);
        if (!userInChannel) {
          console.warn(`用户 ${user.uid} 不在频道中，无法订阅音频`);
          return;
        }
        
        // 添加重试机制
        let retryCount = 0;
        const maxRetries = 8; // 增加重试次数
        
        const attemptSubscribe = async () => {
          try {
            // 再次检查用户是否在频道中
            if (!isUserInChannel(user.uid)) {
              console.warn(`重试时用户 ${user.uid} 不在频道中，放弃订阅`);
              return false;
            }
            
            // 获取最新的用户对象
            const latestRemoteUsers = rtcClient.value.remoteUsers || [];
            const latestUser = latestRemoteUsers.find((remote: any) => remote.uid === user.uid);
            
            if (latestUser) {
              user = latestUser; // 使用最新的用户对象
            } else {
              console.warn(`找不到用户 ${user.uid} 的最新对象，使用原始对象`);
            }
            
            // 检查用户是否有音频轨道
            if (!user.hasAudio) {
              console.warn(`用户 ${user.uid} 没有音频轨道，等待音频轨道发布`);
              await new Promise(resolve => setTimeout(resolve, 1000));
              return false;
            }
            
            // 订阅远程用户的音频轨道
            console.log(`尝试订阅用户 ${user.uid} 的音频轨道...`);
            await rtcClient.value.subscribe(user, mediaType);
            console.log(`成功订阅用户 ${user.uid} 的音频`);
            
            // 检查音频轨道是否存在
            if (!user.audioTrack) {
              console.warn(`用户 ${user.uid} 的音频轨道不存在，等待音频轨道准备就绪`);
              await new Promise(resolve => setTimeout(resolve, 1000));
              return false;
            }
            
            // 播放音频
            console.log(`开始播放用户 ${user.uid} 的音频`);
            user.audioTrack.play();
            return true;
          } catch (error: any) {
            console.warn(`订阅用户 ${user.uid} 的音频失败 (尝试 ${retryCount + 1}/${maxRetries}): ${error.message}`);
            
            // 如果错误是用户不在频道中，等待更长时间再重试
            if (error.message && error.message.includes('not in the channel')) {
              await new Promise(resolve => setTimeout(resolve, 2000));
              
              // 检查用户是否真的不在频道中
              const remoteUsers = rtcClient.value.remoteUsers || [];
              const userStillExists = remoteUsers.some((remoteUser: any) => remoteUser.uid === user.uid);
              
              if (userStillExists) {
                console.log(`用户 ${user.uid} 仍在远程用户列表中，但订阅失败，可能是SDK内部状态不一致`);
                
                // 尝试重新获取用户对象
                const updatedUser = remoteUsers.find((remoteUser: any) => remoteUser.uid === user.uid);
                if (updatedUser && updatedUser !== user) {
                  console.log(`获取到更新的用户对象，尝试使用新对象订阅`);
                  user = updatedUser;
                }
              }
            }
            
            return false;
          }
        };
        
        // 首次尝试订阅
        let subscribeSuccess = await attemptSubscribe();
        
        // 如果失败，进行重试
        while (!subscribeSuccess && retryCount < maxRetries) {
          retryCount++;
          // 每次重试增加延迟
          await new Promise(resolve => setTimeout(resolve, 2000));
          subscribeSuccess = await attemptSubscribe();
        }
        
        if (subscribeSuccess) {
          // 更新用户状态
          const index = participants.value.findIndex(p => p.uid === user.uid);
          if (index !== -1) {
            participants.value[index].isMuted = false;
            console.log(`已更新用户 ${user.uid} 的静音状态为 false`);
          } else {
            console.warn(`用户 ${user.uid} 不在参与者列表中，无法更新状态`);
          }
        } else if (retryCount >= maxRetries) {
          console.error(`订阅用户 ${user.uid} 的音频失败，已达到最大重试次数`);
          
          // 不要立即移除用户，只标记为问题状态
          const index = participants.value.findIndex(p => p.uid === user.uid);
          if (index !== -1) {
            console.warn(`标记用户 ${user.uid} 为音频问题状态`);
            const updatedUser = { 
              ...participants.value[index], 
              isMuted: true,
              hasAudioIssue: true 
            };
            participants.value.splice(index, 1, updatedUser);
          }
        }
      } catch (error: any) {
        console.error(`订阅用户 ${user.uid} 的音频失败:`, error);
      }
    }
  });
  
  // 用户取消发布轨道事件
  rtcClient.value.on('user-unpublished', (user: any, mediaType: string) => {
    if (mediaType === 'audio') {
      // 更新用户状态
      const index = participants.value.findIndex(p => p.uid === user.uid);
      if (index !== -1) {
        participants.value[index].isMuted = true;
      }
    }
  });
  
  // 音量指示器
  try {
    rtcClient.value.enableAudioVolumeIndicator();
    rtcClient.value.on('volume-indicator', (volumes: any[]) => {
      volumes.forEach((volume) => {
        // 更新说话状态
        const index = participants.value.findIndex(p => p.uid === volume.uid);
        if (index !== -1) {
          // 音量大于 5 认为正在说话
          const isSpeaking = volume.level > 5;
          
          // 只有状态变化时才更新，减少不必要的渲染
          if (participants.value[index].isSpeaking !== isSpeaking) {
            participants.value[index].isSpeaking = isSpeaking;
          }
        }
      });
    });
  } catch (error: any) {
    console.warn('启用音量指示器失败:', error);
  }
};

// 检查用户是否在频道中
const isUserInChannel = (uid: number): boolean => {
  try {
    if (!rtcClient.value) {
      console.warn(`RTC客户端不存在，无法检查用户 ${uid} 是否在频道中`);
      return false;
    }
    
    // 检查本地用户
    if (localUid.value === uid) {
      console.log(`用户 ${uid} 是本地用户，确认在频道中`);
      return true;
    }
    
    // 检查远程用户
    const remoteUsers = rtcClient.value.remoteUsers || [];
    
    // 更严格的检查：确保用户不仅在列表中，而且状态是已连接的
    const remoteUser = remoteUsers.find((user: any) => user.uid === uid);
    const userExists = !!remoteUser;
    
    if (userExists) {
      // 检查用户是否有hasJoined属性或其他可以表明完全加入的属性
      const hasFullyJoined = remoteUser.hasJoined !== false;
      console.log(`用户 ${uid} 在远程用户列表中，完全加入状态: ${hasFullyJoined}`);
      return hasFullyJoined;
    } else {
      console.warn(`用户 ${uid} 不在远程用户列表中，可能已离开频道`);
      
      // 检查参与者列表中的状态
      const participantIndex = participants.value.findIndex(p => p.uid === uid);
      if (participantIndex !== -1) {
        const isOnline = participants.value[participantIndex].online !== false;
        console.log(`用户 ${uid} 在参与者列表中，在线状态: ${isOnline}`);
      } else {
        console.log(`用户 ${uid} 不在参与者列表中`);
      }
      
      return false;
    }
  } catch (error: any) {
    console.error(`检查用户 ${uid} 是否在频道中时出错:`, error);
    return false;
  }
};

// 切换静音状态
const toggleMute = async () => {
  if (!localAudioTrack.value) return;
  
  try {
    if (isMuted.value) {
      await localAudioTrack.value.setEnabled(true);
      isMuted.value = false;
    } else {
      await localAudioTrack.value.setEnabled(false);
      isMuted.value = true;
    }
    
    // 更新本地用户状态
    const index = participants.value.findIndex(p => p.isLocal);
    if (index !== -1) {
      participants.value[index].isMuted = isMuted.value;
    }
    
    // 发出静音状态变更事件
    emit('mute-change', isMuted.value);
  } catch (error: any) {
    console.error('切换静音状态失败:', error);
    MessagePlugin.error(`切换静音状态失败: ${error.message || '未知错误'}`);
  }
};

// 离开频道
const leaveChannel = async () => {
  try {
    // 销毁本地音频轨道
    if (localAudioTrack.value) {
      localAudioTrack.value.close();
      localAudioTrack.value = null;
    }
    
    // 离开频道
    if (rtcClient.value) {
      await rtcClient.value.leave();
      rtcClient.value = null;
    }
    
    // 重置状态
    participants.value = [];
    isConnected.value = false;
    isMuted.value = false;
    
    // 发出断开连接事件
    emit('disconnected');
    
    // 发出用户数量变化事件，设置为0
    emit('user-count-change', 0);
    
    MessagePlugin.success('已离开频道');
    
    // 停止状态同步
    stopStatusSync();
  } catch (error: any) {
    console.error('离开频道失败:', error);
    MessagePlugin.error(`离开频道失败: ${error.message || '未知错误'}`);
  }
};

// 测试麦克风
const testMicrophone = async () => {
  if (isTesting.value) return;
  
  try {
    isTesting.value = true;
    testResult.value = '';
    
    // 创建临时音频轨道
    const tempAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    
    // 启动音量检测
    let maxLevel = 0;
    let startTime = Date.now();
    
    testInterval.value = window.setInterval(() => {
      const level = tempAudioTrack.getVolumeLevel() * 100;
      microphoneLevel.value = Math.round(level);
      
      if (level > maxLevel) {
        maxLevel = level;
      }
      
      // 5秒后结束测试
      if (Date.now() - startTime > 5000) {
        clearInterval(testInterval.value as number);
        testInterval.value = null;
        
        // 关闭临时轨道
        tempAudioTrack.close();
        
        // 显示测试结果
        if (maxLevel < 10) {
          testResult.value = '麦克风音量过低，请检查麦克风设置或靠近麦克风说话。';
        } else if (maxLevel < 30) {
          testResult.value = '麦克风音量较低，但可以使用。';
        } else {
          testResult.value = '麦克风工作正常！';
        }
        
        isTesting.value = false;
      }
    }, 100);
    
  } catch (error: any) {
    console.error('麦克风测试失败:', error);
    testResult.value = `麦克风测试失败: ${error.message || '未知错误'}`;
    isTesting.value = false;
  }
};

// 同步用户状态
const syncUserStatus = () => {
  try {
    if (!rtcClient.value) return;
    
    // 获取远程用户列表
    const remoteUsers = rtcClient.value.remoteUsers || [];
    const remoteUserIds = remoteUsers.map((user: any) => user.uid);
    
    console.log(`状态同步：当前远程用户列表 [${remoteUserIds.join(', ')}]`);
    
    // 更新参与者列表中的用户状态
    participants.value.forEach((participant, index) => {
      // 跳过本地用户
      if (participant.isLocal) return;
      
      // 检查用户是否在远程用户列表中
      const isInRemoteList = remoteUserIds.includes(participant.uid);
      const remoteUser = isInRemoteList ? 
        remoteUsers.find((user: any) => user.uid === participant.uid) : null;
      
      // 如果用户不在远程列表中但状态为在线，则标记为离线
      if (!isInRemoteList && participant.online !== false) {
        console.log(`同步状态：用户 ${participant.uid} 不在远程列表中，标记为离线`);
        const updatedUser = { ...participant, online: false };
        participants.value.splice(index, 1, updatedUser);
      }
      
      // 如果用户在远程列表中但状态为离线，则标记为在线
      if (isInRemoteList && participant.online === false) {
        console.log(`同步状态：用户 ${participant.uid} 在远程列表中，标记为在线`);
        const updatedUser = { ...participant, online: true };
        participants.value.splice(index, 1, updatedUser);
      }
      
      // 如果用户在远程列表中且有音频，但标记为静音，则更新状态
      if (isInRemoteList && remoteUser && remoteUser.hasAudio && 
          remoteUser.audioTrack && participant.isMuted) {
        console.log(`同步状态：用户 ${participant.uid} 有音频轨道但标记为静音，更新状态`);
        const updatedUser = { ...participant, isMuted: false };
        participants.value.splice(index, 1, updatedUser);
      }
      
      // 如果用户在远程列表中但没有音频，且标记为非静音，则更新状态
      if (isInRemoteList && remoteUser && (!remoteUser.hasAudio || !remoteUser.audioTrack) && 
          !participant.isMuted) {
        console.log(`同步状态：用户 ${participant.uid} 没有音频轨道但标记为非静音，更新状态`);
        const updatedUser = { ...participant, isMuted: true };
        participants.value.splice(index, 1, updatedUser);
      }
      
      // 如果用户有音频问题，尝试重新订阅
      if (isInRemoteList && participant.hasAudioIssue && !participant.retryingAudio) {
        // 检查是否有音频轨道
        if (remoteUser && remoteUser.hasAudio) {
          console.log(`同步状态：用户 ${participant.uid} 有音频问题，尝试重新订阅`);
          retrySubscribeAudio(participant.uid);
        } else {
          console.log(`同步状态：用户 ${participant.uid} 有音频问题但没有音频轨道，暂不重试`);
        }
      }
    });
    
    // 检查是否有远程用户不在参与者列表中，如果有则添加
    remoteUsers.forEach((remoteUser: any) => {
      const isInParticipants = participants.value.some(p => p.uid === remoteUser.uid);
      if (!isInParticipants) {
        console.log(`同步状态：添加远程用户 ${remoteUser.uid} 到参与者列表`);
        
        // 检查用户是否有音频轨道
        const hasAudio = remoteUser.hasAudio && remoteUser.audioTrack;
        
        participants.value.push({
          uid: remoteUser.uid,
          name: `用户 ${remoteUser.uid}`,
          isMuted: !hasAudio,
          isSpeaking: false,
          isLocal: false,
          online: true
        });
        
        // 如果用户有音频轨道，尝试订阅
        if (hasAudio) {
          console.log(`同步状态：新添加的用户 ${remoteUser.uid} 有音频轨道，尝试订阅`);
          setTimeout(() => {
            retrySubscribeAudio(remoteUser.uid);
          }, 1000);
        }
      }
    });
    
    // 发出用户数量变化事件 - 只统计在线用户
    const onlineUsers = participants.value.filter(p => p.online !== false);
    emit('user-count-change', onlineUsers.length);
  } catch (error: any) {
    console.error('同步用户状态时出错:', error);
  }
};

// 重试订阅音频
const retrySubscribeAudio = async (uid: number) => {
  try {
    // 查找用户在参与者列表中的索引
    const index = participants.value.findIndex(p => p.uid === uid);
    if (index === -1) return;
    
    // 标记为正在重试
    const updatedUser = { ...participants.value[index], retryingAudio: true };
    participants.value.splice(index, 1, updatedUser);
    
    console.log(`尝试重新订阅用户 ${uid} 的音频...`);
    
    // 等待一段时间，确保SDK内部状态已同步
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    // 查找远程用户对象
    const remoteUsers = rtcClient.value.remoteUsers || [];
    const remoteUser = remoteUsers.find((user: any) => user.uid === uid);
    
    if (!remoteUser) {
      console.warn(`找不到远程用户 ${uid}，无法重新订阅`);
      
      // 更新用户状态为重试失败
      const updatedUserAfterFail = { 
        ...participants.value[index], 
        retryingAudio: false,
        hasAudioIssue: true
      };
      participants.value.splice(index, 1, updatedUserAfterFail);
      return;
    }
    
    // 尝试订阅
    let subscribeSuccess = false;
    let retryCount = 0;
    const maxRetries = 3;
    
    while (!subscribeSuccess && retryCount < maxRetries) {
      try {
        // 再次获取最新的用户对象
        const latestRemoteUsers = rtcClient.value.remoteUsers || [];
        const latestUser = latestRemoteUsers.find((user: any) => user.uid === uid);
        
        if (latestUser) {
          console.log(`使用最新的用户对象尝试订阅用户 ${uid} 的音频...`);
          
          // 检查用户是否有音频轨道
          if (!latestUser.hasAudio) {
            console.warn(`用户 ${uid} 没有音频轨道，等待音频轨道发布`);
            await new Promise(resolve => setTimeout(resolve, 1000));
            retryCount++;
            continue;
          }
          
          // 订阅音频
          await rtcClient.value.subscribe(latestUser, 'audio');
          
          if (latestUser.audioTrack) {
            latestUser.audioTrack.play();
            console.log(`成功重新订阅用户 ${uid} 的音频`);
            subscribeSuccess = true;
          } else {
            console.warn(`用户 ${uid} 的音频轨道不存在，等待下一次重试`);
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        } else {
          console.warn(`找不到用户 ${uid} 的最新对象，使用原始对象`);
          
          if (remoteUser.hasAudio) {
            await rtcClient.value.subscribe(remoteUser, 'audio');
            
            if (remoteUser.audioTrack) {
              remoteUser.audioTrack.play();
              console.log(`成功重新订阅用户 ${uid} 的音频`);
              subscribeSuccess = true;
            }
          } else {
            console.warn(`用户 ${uid} 没有音频轨道，无法订阅`);
          }
        }
      } catch (error: any) {
        console.warn(`重试订阅用户 ${uid} 的音频失败 (尝试 ${retryCount + 1}/${maxRetries}): ${error.message}`);
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      
      retryCount++;
    }
    
    // 更新用户状态
    if (subscribeSuccess) {
      const updatedUserAfterRetry = { 
        ...participants.value[index], 
        isMuted: false, 
        hasAudioIssue: false,
        retryingAudio: false
      };
      participants.value.splice(index, 1, updatedUserAfterRetry);
    } else {
      const updatedUserAfterFail = { 
        ...participants.value[index], 
        retryingAudio: false,
        hasAudioIssue: true
      };
      participants.value.splice(index, 1, updatedUserAfterFail);
    }
  } catch (error: any) {
    console.error(`重新订阅用户 ${uid} 的音频失败:`, error);
    
    // 更新用户状态为重试失败
    const index = participants.value.findIndex(p => p.uid === uid);
    if (index !== -1) {
      const updatedUser = { 
        ...participants.value[index], 
        retryingAudio: false,
        hasAudioIssue: true
      };
      participants.value.splice(index, 1, updatedUser);
    }
  }
};

// 设置定时同步用户状态
let statusSyncInterval: number | null = null;

// 在加入频道成功后启动状态同步
const startStatusSync = () => {
  // 清除可能存在的旧定时器
  if (statusSyncInterval) {
    clearInterval(statusSyncInterval);
  }
  
  // 立即执行一次同步
  syncUserStatus();
  
  // 每3秒同步一次用户状态
  statusSyncInterval = window.setInterval(() => {
    syncUserStatus();
  }, 3000);
};

// 在离开频道时停止状态同步
const stopStatusSync = () => {
  if (statusSyncInterval) {
    clearInterval(statusSyncInterval);
    statusSyncInterval = null;
  }
};

// 组件卸载前清理资源
onBeforeUnmount(() => {
  if (isConnected.value) {
    leaveChannel();
  }
  
  // 清理测试定时器
  if (testInterval.value) {
    clearInterval(testInterval.value);
    testInterval.value = null;
  }
  
  // 确保状态同步定时器被清理
  stopStatusSync();
  
  // 确保所有事件监听器被移除
  if (rtcClient.value) {
    try {
      rtcClient.value.removeAllListeners();
    } catch (error: any) {
      console.warn('移除事件监听器时出错:', error);
    }
  }
});

// 暴露方法
__expose({
  toggleMute,
  leaveChannel
});

return (_ctx: any,_cache: any) => {
  const _component_t_input = _resolveComponent("t-input")!
  const _component_t_form_item = _resolveComponent("t-form-item")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_form = _resolveComponent("t-form")!
  const _component_t_dialog = _resolveComponent("t-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_t_dialog, {
      visible: dialogVisible.value,
      header: "实时语音聊天",
      width: 500,
      footer: false,
      "close-on-overlay-click": false,
      "onUpdate:visible": updateVisible
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_t_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_t_form_item, { label: "频道名" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_t_input, {
                      modelValue: channelName.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((channelName).value = $event)),
                      placeholder: "内测功能，请联系管理员"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_t_form_item, { label: "Token" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_t_input, {
                      modelValue: token.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((token).value = $event)),
                      placeholder: "请输入 Token"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_t_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_t_button, {
                      theme: "default",
                      variant: "outline",
                      block: "",
                      onClick: testMicrophone,
                      loading: isTesting.value,
                      disabled: isJoining.value
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" 测试麦克风 ")
                      ])),
                      _: 1
                    }, 8, ["loading", "disabled"])
                  ]),
                  _: 1
                }),
                (microphoneLevel.value !== null)
                  ? (_openBlock(), _createBlock(_component_t_form_item, { key: 0 }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "level-label" }, "麦克风音量:", -1)),
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", {
                              class: "level-bar",
                              style: _normalizeStyle({ width: `${microphoneLevel.value}%` })
                            }, null, 4)
                          ]),
                          _createElementVNode("div", _hoisted_6, _toDisplayString(microphoneLevel.value) + "%", 1)
                        ]),
                        (testResult.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(testResult.value), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_t_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_t_button, {
                      block: "",
                      theme: "primary",
                      onClick: joinChannel,
                      loading: isJoining.value
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" 加入频道 ")
                      ])),
                      _: 1
                    }, 8, ["loading"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    (isConnected.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8))
      : _createCommentVNode("", true)
  ]))
}
}

})