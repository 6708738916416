<template>
  <t-head-menu v-model="menu1Value" theme="light" @change="changeHandler">
    <template #logo>
      <img height="49" src="https://we.emoera.com/img/elogo.jpg" alt="logo" style="border-radius: 30%"/>
    </template>
    <t-menu-item value="item1" @click="goToHome">
      <template #icon>
        <t-icon name="code" />
      </template>
      E时代IDE
    </t-menu-item>
    <t-menu-item value="item2" @click="openExternalLink">
      <template #icon>
        <t-icon name="history" />
      </template>
      更新日志
    </t-menu-item>
    <t-menu-item value="item4" :disabled="true">
      <template #icon>
        <t-icon name="tag" />
      </template>
      v1.3.3
    </t-menu-item>
    
    <template #operations>
      <t-dropdown v-if="userStore.isLoggedIn" trigger="click">
        <t-button variant="text" class="user-btn">
          <t-avatar size="small" :image="userStore.userInfo?.avatar">
            {{ userStore.userInfo?.username?.charAt(0)?.toUpperCase() }}
          </t-avatar>
          <span class="username">{{ userStore.userInfo?.username }}</span>
        </t-button>
        <template #dropdown>
          <t-dropdown-menu>
            <t-dropdown-item value="profile" @click="router.push('/profile')">
              <template #icon>
                <t-icon name="user" />
              </template>
              个人中心
            </t-dropdown-item>
            <t-dropdown-item value="settings" @click="goToAccountCenter">
              <template #icon>
                <t-icon name="setting" />
              </template>
              账号设置
            </t-dropdown-item>
            <t-dropdown-item value="logout" theme="danger" @click="handleLogout">
              <template #icon>
                <t-icon name="poweroff" />
              </template>
              退出登录
            </t-dropdown-item>
          </t-dropdown-menu>
        </template>
      </t-dropdown>
      
      <t-button v-else variant="text" @click="router.push('/login')">
        <template #icon>
          <t-icon name="user" />
        </template>
        登录
      </t-button>
    </template>
  </t-head-menu>

  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>

  <div class="icp-info">
    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
      蜀ICP备2024055741号
    </a>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';
import { useSnippetsStore } from '@/store/snippets';
import { MessagePlugin } from 'tdesign-vue-next';

const router = useRouter();
const userStore = useUserStore();
const snippetsStore = useSnippetsStore();
const menu1Value = ref('item1');

// 在组件挂载时初始化代码片段
onMounted(async () => {
  if (userStore.isLoggedIn) {
    try {
      await snippetsStore.initialize();
    } catch (error) {
      console.error('初始化代码片段失败:', error);
    }
  }
});

// 跳转到账号中心
const goToAccountCenter = () => {
  window.open('https://account.emoera.com', '_blank');
};

// 处理退出登录
const handleLogout = async () => {
  try {
    const response = await fetch('/api/auth/logout', {
      method: 'POST',
      credentials: 'include'
    });
    
    const data = await response.json();
    if (data.success) {
      userStore.clearUserInfo();
      MessagePlugin.success('已退出登录');
      window.location.href = '/login';
    } else {
      throw new Error(data.message || '退出登录失败');
    }
  } catch (error) {
    MessagePlugin.error('退出登录失败，请重试');
    console.error('退出登录失败:', error);
  }
};

function openExternalLink() {
  window.open('https://docs.qq.com/aio/DVHZpRFFTdUVIYlV2?p=BvAba1pUjsuoDHKNY65azz', '_blank');
}

function goToHome() {
  router.push('/');
}

const changeHandler = (active: string | number) => {
  console.log('change', active);
};
</script>

<style lang="less">
html body {
  margin: 8px;
}

.t-menu__operations {
  padding-right: 10px;

  .user-btn {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 48px;
    padding: 0 16px;
    margin-left: 8px;
    border-radius: 6px;
    transition: all 0.2s ease;
    
    &:hover {
      background-color: var(--td-bg-color-container-hover);
    }
  }

  .username {
    font-size: 15px;
    font-weight: 400;
    color: var(--td-text-color-primary);
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: 0.2px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Microsoft YaHei", sans-serif;
  }
}

:deep(.t-dropdown) {
  .t-dropdown__menu {
    min-width: 120px;
  }

  .t-dropdown-item {
    font-size: 14px;
    padding: 8px 16px;
    
    &:hover {
      background-color: var(--td-bg-color-container-hover);
    }
    
    &.t-dropdown-item--danger {
      color: var(--td-error-color);
      
      &:hover {
        background-color: var(--td-error-color-1);
      }
    }
  }
}

:deep(.t-avatar) {
  background-color: var(--td-brand-color-1);
  color: var(--td-brand-color);
  font-weight: 500;
  width: 32px;
  height: 32px;
  border: 1px solid var(--td-component-stroke);
}

// 添加菜单图标样式
:deep(.t-menu__item) {
  .t-icon {
    font-size: 18px;
    margin-right: 6px;
  }
}

:deep(.t-dropdown-item) {
  .t-icon {
    font-size: 16px;
    margin-right: 8px;
  }
}

// 移除所有主题相关的样式
:deep(.t-menu__item.t-is-disabled) {
  opacity: 0.8;
  .t-icon {
    color: var(--td-brand-color);
  }
}

// 添加备案信息样式
.icp-info {
  text-align: center;
  padding: 10px 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.1);
  
  a {
    color: rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: color 0.2s ease;
    
    &:hover {
      color: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
